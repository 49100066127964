import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import CreateNewScrimmage from "../component/CreateNewScrimmage";
import ScrimmageDetails from "../component/ScrimmageDetails";
import axios from 'axios';
import { apiUrl } from '../../config';
import { requestUploadImage } from '../../actions/imageActionCreator';
import Spinner from '../../pages/component/Spnner';
import { requestTeamCoverBG, requestTeamDetailsData } from '../../actions/teamDataActionCreator';
import { requestScrimmageRequest, requestScrimmageInvitation, requestScrimmageConfirmation, requestScrimmageHistory, requestPaymentStatus } from '../../actions/scrimmageActionCreator'

let expire = "", today = "", length = "";


class TeamDetails extends Component {
    constructor(props) {
        super(props);
        axios.defaults.baseURL = apiUrl;
        this.state = {
            scrimmageIsAvaliable: true,
            token: "",
            name: "",
            sports: "",
            description: "",
            imgUrl: "",
            coverFile: "",
            coverImage: "",
            ownerId: "",
            ownerFirstName: "",
            ownerEmail: "",
            ownerPhone: "",
            ownerLastName: "",
            ownerImg: null,
            locationZipcode: "",
            locationLat: null,
            locationLong: null,
            gender: "",
            distance: "",
            skillLevel: "",
            ageGroup: "",
            availability: [],
            id: "",
            dateEntered: "",
            dateModified: "",
            active: null,
            deleted: null,
            bgCover: "",
            urlTeamId: "",
            currentUrl: ""
        };
    }

    componentDidMount = () => {
        this.setState({ currentUrl: window.location.pathname })
        this.setState({
            token: localStorage.getItem("user_token"),
            id: this.props.match.params.id,
            urlTeamId: window.location.pathname.split("/")[2]
        }, () => {
            this.getDetails();
            this.props.requestScrimmageRequest(this.state.urlTeamId);
            this.props.requestScrimmageInvitation(this.state.urlTeamId);
            this.props.requestScrimmageConfirmation(this.state.urlTeamId);
            this.props.requestScrimmageHistory(this.state.urlTeamId);
        })
    }

    getDetails = () => {
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.state.token
        let id = this.props.match.params.id;
        let userid = localStorage.getItem("user_id")
        this.props.requestTeamDetailsData(id)
        this.props.requestPaymentStatus(userid);
    }

    UploadCoverImage = (event) => {
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.state.token
        if (event.target.files[0] !== undefined) {
            this.props.requestUploadImage(event.target.files[0]);
            setTimeout(() => {
                if (this.props.image.ImageState.image) {
                    const data =
                    {
                        "name": this.state.name,
                        "description": this.state.description,
                        "imageUrl": this.state.imgUrl,
                        "coverImage": this.props.image.ImageState.image && this.props.image.ImageState.image.toString(),
                        "location": {
                            "zipCode": this.state.locationZipcode,
                        },
                        "gender": this.state.gender,
                        "distance": this.state.distance,
                        "skillLevel": this.state.skillLevel,
                        "ageGroup": this.state.ageGroup,
                        "availability": this.state.availability,
                    }
                    let teamId = this.props.match.params.id;
                    this.props.requestTeamCoverBG(teamId, data);
                }
            }, 2000)

        }
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            bgCover: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.coverImage,
            name: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.name,
            sports: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.sport,
            description: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.description,
            imgUrl: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.imageUrl,
            ownerId: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.owner.id,
            ownerFirstName: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.owner.firstName,
            ownerEmail: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.owner.email,
            ownerPhone: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.owner.phone,
            ownerLastName: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.owner.lastName,
            ownerImg: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.owner.imageUrl,
            locationZipcode: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.location.zipCode,
            locationLat: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.location.lat,
            locationLong: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.location.long,
            gender: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.gender,
            distance: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.distance,
            skillLevel: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.skillLevel,
            ageGroup: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.ageGroup,
            availability: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.availability,
            id: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.id,
            dateEntered: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.dateEntered,
            dateModified: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.dateModified,
            active: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.active,
            deleted: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.deleted,
        })

    }

    render() {
        let expireDate = this.props.scrimmagePaymentData && this.props.scrimmagePaymentData.plans[0] && this.props.scrimmagePaymentData.plans[0].planExpire
        expire = new Date(Date.parse(expireDate)).toLocaleDateString()
        today = new Date().toLocaleDateString()
        length = this.props.scrimmagePaymentData && this.props.scrimmagePaymentData.plans.length
        return (
            <div>
                <div className="bg-img-hero-bottom user-bg" style={this.state.bgCover ? { backgroundImage: `linear-gradient(90deg, rgba(94,131,195,0.7)0%, rgba(146,146,146,0.5)), url(${this.state.bgCover})` } : { backgroundImage: "linear-gradient(90deg, rgba(94,131,195,0.7) 0%, rgba(146,146,146,0.5) 100%" }}>
                    <Spinner display={this.props.teamDetailsData.isLoading}>
                        <div className="d-flex align-items-end height-400">

                            <div className="container space-2-bottom">
                                <div className="row align-items-sm-end">
                                    <div className="col-md-6 mb-5 d-md-flex align-items-center">
                                        <img src={this.state.imgUrl ? this.state.imgUrl : "/img/dummy.jpg"} className="teamlogo mr-3" alt="teamlogo" />
                                        <div className="d-inline-block mx-3">
                                            <h1 className="text-white team-name text-uppercase font-weight-bold">{this.state.name}</h1>
                                            <h3 className="text-white">{this.state.sports}</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-left text-md-right clearfix">
                                        <label className="d-md-flex float-left float-sm-right">
                                            <Link to={"/createteam/" + this.props.match.params.id} className="btn btn-sm btn-outline-primary mr-1">Edit Team</Link>
                                            <input type="file" accept="image/*" onChange={this.UploadCoverImage} />
                                            <div className="m-auto mobile-hide">
                                                <div className="d-flex">
                                                    <div className="btn btn-sm btn-outline-primary">Update Cover Image</div>
                                                </div>
                                            </div>
                                        </label>                                        
				        <Link to={"/scrimmagerequest/" + this.state.id} className="float-right">
					  <button type="button" className="btn btn-sm btn-primary mr-1 create-scrimmage-btn d-lg-none font-weight-bold">New Scrimmage Request</button>
				        </Link>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </Spinner>
                </div>
                <Spinner display={this.props.teamDetailsData.isLoading}>
                    {
                        this.state.scrimmageIsAvaliable ? (<ScrimmageDetails />) : null
                        /* this.state.scrimmageIsAvaliable ? (<ScrimmageDetails />) : (<CreateNewScrimmage />) */
                    }
                </Spinner>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        teamCoverbg: state.status,
        image: state,
        teamDetailsData: state.TeamState,
        isLoading: state.TeamState.isLoading,
        isLoadingScrimmage: state.ScrimmageState,
        isLoadingCoverBG: state.ImageState.isLoading,
        scrimmagePaymentData: state.ScrimmageState.scrimmagePaymentData
    }

};

const mapDispatchToProps = {
    requestUploadImage,
    requestTeamCoverBG,
    requestTeamDetailsData,
    requestScrimmageRequest,
    requestScrimmageInvitation,
    requestScrimmageConfirmation,
    requestPaymentStatus,
    requestScrimmageHistory
};
export default connect(mapStateToProps, mapDispatchToProps)(TeamDetails)