const defaultImageState = {
    image: null,
    isLoading: false,
    status: 0
};


export default function (state = defaultImageState, action) {

    switch (action.type) {

        case 'DEFAULT_SCRIMMAGE':
            return { ...state, isLoading: false, status: 0, forgotPassStatus:null };

        case 'REQUESTED_UPLOAD':
            return { ...state, isLoading: true, status:1 };
        case 'RECIVED_UPLOAD_FAILURE':
            return { ...state, isLoading: false, status:2 };
        case 'RECIVED_UPLOAD_SUCCESS':
            return { ...state, image: action.image, isLoading: false, status:3 };

        case 'REQUESTED_TEAM_COVER_BG':
            return { ...state, isLoading: true };
        case 'RECIVED_TEAM_COVER_BG_SUCCESS':
            return { ...state, teamCoverbg: action.teamCoverbg, isLoading: false };
        case 'RECIVED_TEAM_COVER_BG_FAILURE':
            return { ...state, isLoading: false };
        default:

    }

    return state
}