
const defaultUserProfileState = {
    profileData: null,
    profilePassErr:null,
    isLoading: false,
    status: 0
};

export default function (state = defaultUserProfileState, action) {

    switch (action.type) {

        
        case 'DEFAULT_SCRIMMAGE':
            return { ...state, isLoading: false, status: 0, forgotPassStatus:null, };
            
        case 'REQUESTED_PROFILE_DATA':
            return { ...state, isLoading: true,errorStatus:"" };
        case 'RECIVED_PROFILE_DATA_SUCCESS':
            return { ...state, profileData: action.profileData, isLoading: false,errorStatus:"" };
        case 'RECIVED_PROFILE_DATA_FAILURE':
                return { ...state, errorStatus: action.errorStatus, isLoading: false };

        case 'REQUESTED_PROFILE_DATA_UPDATED':
            return { ...state, isLoading: true, status: 1 };
        case 'RECIVED_PROFILE_DATA_UPDATED_SUCCESS':
            return { ...state, updatedProfileData: action.updatedProfileData, isLoading: false ,status: 3 };
        case 'RECIVED_PROFILE_DATA_UPDATED_FAILURE':
            return { ...state, isLoading: false, status: 2 };

            case 'REQUESTED_PROFILE_PASSWORD_VARIFY':
                return { ...state, isLoading: true, status: 1 };
            case 'RECIVED_PROFILE_PASSWORD_VARIFY_SUCCESS':
                return { ...state, isLoading: false, status: 3};
            case 'RECIVED_PROFILE_PASSWORD_VARIFY_FAILURE':
                return { ...state, profilePassErr:action.profilePassErr, isLoading: false, status: 2 };
        default:

    }
    return state
}