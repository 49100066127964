import React, { Component } from 'react';
import Moment from "react-moment"
import moment from 'moment';
import { connect } from 'react-redux';
import StarRatingComponent from "react-star-rating-component";
import { Link } from "react-router-dom";
import { apiUrl } from '../../config';
import 'moment-timezone';
import { FaArrowLeft } from 'react-icons/fa'
import axios from "axios";
import Calendar from 'react-calendar';
import {
    requestScrimmageRequest,
    requestScrimmageInvitation,
    requestScrimmageConfirmation,
    requestScrimmageDecline,
    requestScrimmageAccept,
    requestScrimmageCancel,
    requestScrimmageHistory,
    requestScrimmageFeedback,
    requestScrimmageFeedbackData,
    requestScrimmageData,
    initialDefault
} from '../../actions/scrimmageActionCreator'

class ScrimmageDetails extends Component {
    constructor(props) {
        super(props);
        axios.defaults.baseURL = apiUrl;
        axios.defaults.headers.common['Authorization'] = 'bearer ' + localStorage.getItem("user_token")
        this.state = {
            rating: 0,
            sports: "",
            history: true,
            outgoing: false,
            incoming: false,
            confirmed: false,
            showCalendar: false,
            date: "",
            dateValue: "",
            teamId: "",
            token: "",
            scrimmageDataList: [],
            scrimmageInvitationData: [],
            scrimmageConfirmationData: [],
            requestId: "",
            url: "",
            gameFeedback: "",
            userPaymentLength: "",
            userPaymentDate: "",
            currentUrl: ""
        };
    }

    showCalander = (key, data) => {
        let date = this.props.scrimmageRequestData.scrimmageConfirmationData && this.props.scrimmageRequestData.scrimmageConfirmationData[key].game.startDate
        this.setState({ showCalendar: true, date: new Date(date) })
    }

    componentDidMount = () => {
        this.setState({ currentUrl: window.location.pathname })
        const newHeader = {
            Authorization: "Bearer " + localStorage.getItem("user_token"),
        }
        let userid = localStorage.getItem("user_id")
        axios.get("user/" + userid, { headers: newHeader }).then((res => {
            this.setState({
                userPaymentLength: res.data.plans.length,
                userPaymentDate: res.data.plans[0] && res.data.plans[0].planExpire
            })
        }))
        this.props.initialDefault();
        //let feedbackid = localStorage.getItem("feedbackId")
        //let gameid = localStorage.getItem("gameId");
        // this.setState({
        //     url: window.location.pathname.split("/")[2]
        // }, () => {
        //     this.props.requestScrimmageData(this.state.url);
        // })

        let date = localStorage.getItem("Date")
        this.setState({
            dateValue: date,
            token: localStorage.getItem("user_token"),
            teamId: window.location.pathname.split("/")[2]
        }, () => {
            axios.defaults.headers.common['Authorization'] = 'bearer ' + this.state.token
            this.checkTabName();
        })
        localStorage.setItem("name", 'Confirmed');
    }

    checkTabName = () => {
        let activeTab = localStorage.getItem("name");
        switch (activeTab) {
            case 'History':
                this.setState({
                    history: true,
                    outgoing: false,
                    incoming: false,
                    confirmed: false
                })
                break;
            case 'Outgoing':
                this.setState({
                    history: false,
                    outgoing: true,
                    incoming: false,
                    confirmed: false
                })
                break;
            case 'Incoming':
                this.setState({
                    history: false,
                    outgoing: false,
                    incoming: true,
                    confirmed: false
                })
                break;
            case 'Confirmed':
                this.setState({
                    history: false,
                    outgoing: false,
                    incoming: false,
                    confirmed: true
                })
                break;
            default:
        }
    }

    onStarHover = (nextValue) => {
        this.setState({ rating: nextValue });
    }
    handleClick = (name) => {
        localStorage.setItem("name", name);
        switch (name) {
            case 'History':
                this.props.requestScrimmageHistory(this.state.teamId);
                break;
            case 'Outgoing':
                this.props.requestScrimmageRequest(this.state.teamId);
                break;
            case 'Incoming':
                this.props.requestScrimmageInvitation(this.state.teamId);
                break;
            case 'Confirmed':
                this.props.requestScrimmageConfirmation(this.state.teamId);
                break;
            default:
        }
    }

  componentWillReceiveProps = (nextProps) => {
        this.checkTabName();
        this.setState({
            sports: nextProps.teamDetailsData.teamDetailsData && nextProps.teamDetailsData.teamDetailsData.sport,
            scrimmageDataList: nextProps.scrimmageRequestData.scrimmageRequestData,
            scrimmageInvitationData: nextProps.scrimmageRequestData.scrimmageInvitationData,
            scrimmageConfirmationData: nextProps.scrimmageRequestData.scrimmageConfirmationData
        })

        if (nextProps.scrimmageRequestData && nextProps.scrimmageRequestData.status === 3) {
            window.location.reload();
        }
    }

    handleRejectScrimmage = (requestId) => {
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.state.token
        const declineData = {
            refereeRequest: null,
            status: "Declined"
        }
        this.props.requestScrimmageDecline(requestId, declineData)
    }

    handleScheduleScrimmage = (requestId) => {
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.state.token
        const scheduleData = {
            refereeRequest: null,
            status: "Scheduled"
        }
        this.props.requestScrimmageAccept(requestId, scheduleData)
    }

    handleDeleteOutgoingScrimmage = (gameid) => {
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.state.token
        const data =
        {
            status: "Cancelled"
        }
        this.props.requestScrimmageCancel(data, gameid)
    }

    handleGetGameId = (id) => {
        localStorage.setItem("gameId", id)
    }

    handleScrimmageUpdate = () => {
        let teamid = window.location.pathname.split('/')[2]
        let gameid = localStorage.getItem("gameId");
        const data = {
            Rating: this.state.rating,
            Comment: this.state.gameFeedback
        }
        this.props.requestScrimmageFeedback(gameid, teamid, data)
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }

    handleType = () => {
        var totalChar = document.getElementById("charlimitupdate").value;
        document.getElementById("charcountvalidate").innerHTML = totalChar.length + "/150";
    }
    
    render() {
        let expire = new Date(Date.parse(this.state.userPaymentDate)).toLocaleDateString()
        let today = new Date().toLocaleDateString()
        let time = "";
        let urlId = this.props.teamDetailsData.teamDetailsData && this.props.teamDetailsData.teamDetailsData.id;
        return (
            <div className="container-fluid space-1">
                <div className="row position-relative">
                    <div className="d-none w-lg-0 d-lg-block mx-md-auto space-1-bottom">
		      <Link to={"/scrimmagerequest/" + urlId}>
			<button type="button" className="btn btn-primary create-scrimmage-btn font-weight-bold">
			    New Scrimmage Request
			</button>
		      </Link>
		    </div>

                    <div className="col-lg-12">
                        <ul className="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
                            <li className="nav-item mt-1 mt-lg-0 mx-1 mb-md-1 w-md-auto w-100">
                                <a className={`nav-link js-animation-link btn btn-outline-light btn-wide ${this.state.confirmed ? "active" : ""}`} id="pills-Scheduled-tab" data-toggle="pill" href="#pills-Scheduled" role="tab" aria-controls="pills-Scheduled" aria-selected={this.state.confirmed ? "true" : "false"} onClick={() => this.handleClick("Confirmed")}>Confirmed Scrimmages</a>
                            </li>
                            <li className="nav-item mt-1 mt-lg-0 mx-1 mb-md-1 w-md-auto w-100">
                                <a className={`nav-link js-animation-link btn btn-outline-light btn-wide ${this.state.outgoing ? "active" : ""}`} id="pills-Request-tab" data-toggle="pill" href="#pills-Request" role="tab" aria-controls="pills-Request" aria-selected={this.state.Outgoing ? "true" : "false"} onClick={() => this.handleClick("Outgoing")}>Outgoing Scrimmage Requests</a>
                            </li>
                            <li className="nav-item mt-1 mt-lg-0 mx-1 mb-md-1 w-md-auto w-100">
                                <a className={`nav-link js-animation-link btn btn-outline-light btn-wide ${this.state.incoming ? "active" : ""}`} id="pills-Invites-tab" data-toggle="pill" href="#pills-Invites" role="tab" aria-controls="pills-Invites" aria-selected={this.state.incoming ? "true" : "false"} onClick={() => this.handleClick("Incoming")}>Incoming Scrimmage Invites</a>
                            </li>
                            <li className="nav-item mt-1 mt-lg-0 mx-1 mb-md-1 w-md-auto w-100">
                                <a className={`nav-link js-animation-link btn btn-outline-light btn-wide ${this.state.history ? "active" : ""}`} id="pills-History-tab" data-toggle="pill" href="#pills-History" role="tab" aria-controls="pills-History" aria-selected={this.state.history ? "true" : "false"} onClick={() => this.handleClick("History")}>Scrimmage History</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="pr-lg-4">
                            <div className="mb-4">
                                <div className="tab-content" id="pills-tabContent">
                                    <div className={`tab-pane fade ${this.state.history ? "show active" : ""}`} id="pills-History" role="tabpanel" aria-labelledby="pills-History-tab">
                                        <div className="row">
                                            {
                                                this.props.scrimmageHistory && this.props.scrimmageHistory.length !== 0 ? this.props.scrimmageHistory.reverse().map((data, key) => {

                                                    return (
                                                        <div className="col-lg-4 col-md-6" key={key}>
                                                            <article className="card mb-5 text-center shadow-lg">
                                                                <div className="card-body py-5 px-2 text-center">
                                                                    <p className={`h5 custom-p ${data.feedback.length !== 0 ? "" : "updatemodal"}`}>

                                                                        {
                                                                            data.feedback.length !== 0 ?
                                                                                (<StarRatingComponent
                                                                                    name="rate1"
                                                                                    editing={false}
                                                                                    starCount={5}
                                                                                    value={data.feedback[0] && data.feedback[0].rating}
                                                                                    onStarHover={this.onStarHover}
                                                                                />)
                                                                                :
                                                                                (<a href="#!" className="border border-color updatestatus py-2 px-4" data-toggle="modal" data-target="#updateresult" onClick={() => this.handleGetGameId(data.id)}>Update Status</a>)
                                                                        }
                                                                    </p>
                                                                    <p className="mb-0 match-result">{data.feedback[0] && data.feedback[0].comment}</p>
                                                                    <h1 className="custom-h1">{data && localStorage.getItem("user_id") === data.fromUser.id ? data.toTeam.name : data.fromTeam.name}</h1>
                                                                    <p><Moment format="MM/DD/YYYY">{data && data.game.startDate}</Moment></p>
                                                                </div>
                                                                <div className="card-footer py-4 px-5">
                                                                    <div className="media">
                                                                        <div className="media-body">
                                                                            <Link className="btn btn-primary btn-wide m-1 my-lg-0 font-weight-bold" to={"/scrimmageinvitation/" + data.game.id + "/" + localStorage.getItem("user_id") + "?key=" + localStorage.getItem("user_token") + "&requestId=" + data.id + "&hide"}>View Scrimmage</Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    )
                                                })
                                                    :
                                                    (
                                                        <div className="mx-auto text-center my-lg-5">
                                                            <p className="mb-3">No scrimmage history found.</p>
                                                            {/* <p><a className="btn btn-secondary" href={"/scrimmagerequest/" + urlId}>Create Your First Scrimmage</a></p> */}
                                                            <p></p>
                                                        </div>
                                                    )
                                            }
                                        </div>
                                    </div>
                                    <div className={`tab-pane fade ${this.state.outgoing ? "show active" : ""}`} id="pills-Request" role="tabpanel" aria-labelledby="pills-Request-tab">
                                        <div className="row">
                                            {
                                                this.state.scrimmageDataList && this.state.scrimmageDataList.length !== 0 ? this.state.scrimmageDataList.reverse().map((data, key) => {
                                                    let time = new Date(Date.parse(data && data.startDate))
                                                    return (
                                                        <div className={"col-lg-4 col-md-6 status-" + data.status} key={key}>
                                                            <article className="card mb-5 text-center shadow-lg">
                                                                <div className="card-body pt-5 px-5 text-center">
                                                                    <h2 className="h5 text-uppercase font-weight-bold location-name">
                                                                        {data.location && data.location.name}
                                                                    </h2>
                                                                    <p className="outgoing-address mb-0">{data.location && data.location.address1}</p>
                                                                    <p className="outgoing-address">{data.location && data.location.city}, {data.location && data.location.state}, {data.location && data.location.zipCode}</p>
                                                                    <p><Moment format="M/DD/YYYY">{data.startDate && data.startDate.split("T")[0]}</Moment> at <Moment format="h:mm A" local>{time}</Moment></p>
                                                                </div>
                                                                <div className="card-footer py-4 px-5">
                                                                    <div className="media">
                                                                        <div className="media-body d-flex">
                                                                            {
                                                                                data.status === "Cancelled" ?
                                                                                    (<h4 className="text-danger w-100 text-center">Cancelled</h4>)
                                                                                    :
                                                                                    (
                                                                                        <>
                                                                                            <button type="button" className="btn btn-outline-light m-1 my-lg-0 w-100 font-weight-bold" onClick={() => this.handleDeleteOutgoingScrimmage(data.id)}>Cancel</button>
                                                                                            <a className="btn btn-primary m-1 my-lg-0 w-100 font-weight-bold" href={"/scrimmageinvitation/" + data.id + "/" + localStorage.getItem("user_id") + "?key=" + localStorage.getItem("user_token") + "&requestId=" + data.id + "&hide"}>View</a>
                                                                                        </>
                                                                                    )
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    )
                                                })
                                                    :
                                                    (
                                                        <div className="mx-auto text-center my-lg-5">
                                                            <p className="mb-3">No scrimmage request found.</p>
                                                            {/* <p><a className="btn btn-secondary" href={"/scrimmagerequest/" + urlId}>Create Your First Scrimmage</a></p> */}
                                                            <p></p>
                                                        </div>
                                                    )
                                            }
                                        </div>
                                    </div>
                                    <div className={`tab-pane fade ${this.state.incoming ? "show active" : ""}`} id="pills-Invites" role="tabpanel" aria-labelledby="pills-Invites-tab">
                                        <div className="row">
                                            {
                                                this.state.scrimmageInvitationData && this.state.scrimmageInvitationData.length !== 0 ? this.state.scrimmageInvitationData.reverse().map((data, key) => {
                                                    let invitationtime = new Date(Date.parse(data.game && data.game.startDate))
                                                    return (
                                                        <div className="col-lg-4 col-md-6" key={key}>
                                                            <article className="card mb-5 text-center shadow-lg">
                                                                <div className="card-body pt-5 px-5 text-center">
                                                                    {
                                                                        data.status === "Rejected" ?
                                                                            (<span className="status-waiting text-danger custom-border-color float-right m-2">Rejected</span>)
                                                                            :
                                                                            ("")
                                                                    }
                                                                    <div className="card-body pt-5 px-5 text-center">
                                                                        <h2 className="h5 text-uppercase font-weight-bold location-name">
                                                                            {data.fromTeam && data.fromTeam.name}
                                                                        </h2>
                                                                        <p className="outgoing-address mb-0">{data.game.location && data.game.location.address1}</p>
                                                                        <p className="outgoing-address">{data.game.location && data.game.location.city}, {data.game.location && data.game.location.state}, {data.game.location && data.game.location.zipCode}</p>
                                                                        <p><Moment format="M/DD/YYYY">{data.game && data.game.startDate.split("T")[0]}</Moment> at <Moment format="h:mm A" local>{invitationtime}</Moment></p>
                                                                    </div>
                                                                </div>
                                                                <div className="card-footer py-4 px-2">
                                                                    <div className="media">
                                                                        <div className="media-body d-flex">
                                                                            {
                                                                                data.status === "Closed" ?
                                                                                    (<div className="status-waiting text-danger custom-border-color btn-wide mx-auto my-1 my-lg-0">Closed</div>)
                                                                                    :
                                                                                    data.status === "Cancelled" ?
                                                                                        (<div className="status-waiting text-danger custom-border-color btn-wide mx-auto my-1 my-lg-0">Cancelled</div>)
                                                                                        :
                                                                                        (<a className="btn btn-primary btn-wide mx-auto my-1 my-lg-0 font-weight-bold" href={"/scrimmageinvitation/" + data.game.id + "/" + localStorage.getItem("user_id") + "?key=" + localStorage.getItem("user_token") + "&teamId=" + window.location.pathname.split("/")[2] + "&requestId=" + data.id}>View Scrimmage</a>)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    )
                                                })
                                                    :
                                                    (
                                                        <div className="mx-auto text-center my-lg-5">
                                                            <p className="mb-3">No scrimmage invitation found.</p>
                                                            {/* <p><a className="btn btn-secondary" href={"/scrimmagerequest/" + urlId}>Create Your First Scrimmage</a></p> */}
                                                            <p></p>
                                                        </div>
                                                    )
                                            }
                                        </div>
                                    </div>

                                    <div className={`tab-pane fade ${this.state.confirmed ? "show active" : ""}`} id="pills-Scheduled" role="tabpanel" aria-labelledby="pills-Scheduled-tab">

                                        {
                                            this.state.scrimmageConfirmationData && this.state.scrimmageConfirmationData.length !== 0 ? this.state.scrimmageConfirmationData.reverse().map((data, key) => {
                                                let scheduleTime = new Date(Date.parse(data.game && data.game.startDate))
                                                												                                                                                                
                                                return (
                                                    <div className="row">
                                                        <div className="col" key={key}>
                                                            <ul className="list-unstyled">
                                                                <li className="my-4">                                                                   
                                                                    <div className="d-block card-frame rounded p-5">
                                                                        <div className="row">
                                                                            <div className="col-12 col-lg-3">
                                                                                <label className="mb-0 text-uppercase">OPPONENT:</label><h4 className="font-weight-bold">{data && localStorage.getItem("user_id") === data.fromUser.id ? data.toTeam.name : data.fromTeam.name}</h4>
                                                                                <a target="_blank" rel="noopener noreferrer" className="btn btn-purple email-coach-btn my-2 mx-md-2 mx-lg-0 my-lg-2" href={"mailto:" + (data && localStorage.getItem("user_id") === data.fromUser.id ? data.toUser.email : data.fromUser.email)}>Email Coach</a>
                                                                            </div>
                                                                            <div className="col-12 col-lg-7">
                                                                                <div className="row">
                                                                                    <div className="col-6 col-md-4">
                                                                                        <label className="mb-0 text-uppercase">Team age</label>
                                                                                        <p>{data && data.fromTeam.ageGroup}</p>
                                                                                    </div>
                                                                                    <div className="col-6 col-md-4">
                                                                                        <label className="mb-0 text-uppercase">Team gender</label>
                                                                                        <p style={{textTransform: 'capitalize'}}>{data && data.fromTeam.gender}</p>
                                                                                    </div>
                                                                                    <div className="col-6 col-md-4">
                                                                                        <label className="mb-0 text-uppercase">Team Skill Level</label>
                                                                                        <p>{data && data.fromTeam.skillLevel}</p>
                                                                                    </div>
                                                                                    <div className="col-6 col-md-4">
                                                                                        <label className="mb-0 text-uppercase">Date</label>
                                                                                        <p /* onClick={() => this.showCalander(key, data)} data-toggle="modal" data-target="#calender" */><Moment format="M/DD/YYYY">{data && data.game.startDate}</Moment></p>

                                                                                    </div>
                                                                                    <div className="col-6 col-md-4">
                                                                                        <label className="mb-0 text-uppercase">Venue</label>
                                                                                        <p>{data.game.location && data.game.location.name}</p>
                                                                                    </div>
                                                                                    <div className="col-6 col-md-4">
                                                                                        <label className="mb-0 text-uppercase">Scrimmage Time</label>
                                                                                        <p><Moment format="h:mm A" local>{scheduleTime}</Moment></p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 col-lg-2">
                                                                                <div className="media float-right d-block w-100">
                                                                                    {
                                                                                        data.status && data.status === "Accepted" ?
                                                                                            localStorage.getItem("user_id") === data.toUser.id ?
                                                                                                (<p className="status-waiting custom-color text-center custom-border-color">Waiting</p>)
                                                                                                :
                                                                                                <>
                                                                                                    <button type="button" className="btn btn-outline-light my-2 my-lg-2 d-lg-block w-100" onClick={() => this.handleRejectScrimmage(data.id)}>Decline</button>
                                                                                                    <button type="button" className="btn btn-primary my-2 mx-md-2 mx-lg-0 my-lg-2 w-100" onClick={() => this.handleScheduleScrimmage(data.id)}>Schedule</button>
                                                                                                </>
                                                                                            :
                                                                                            data.status && data.status === "Scheduled" ?
                                                                                                (
                                                                                                    <>
                                                                                                        <h4 className="font-weight-bold">SCHEDULED</h4>
                                                                                                        <a className="btn btn-primary my-2 mx-md-2 mx-lg-0 my-lg-2 w-100" href={"/scrimmageinvitation/" + data.game.id + "/" + localStorage.getItem("user_id") + "?key=" + localStorage.getItem("user_token") + "&requestId=" + data.id + "&hide&refereeRequest=" + data.refereeRequest}>View</a>
                                                                                                    </>
                                                                                                )
                                                                                                :
                                                                                                data.status && data.status === "Declined" ?
                                                                                                    (<p className="status-waiting text-danger text-center custom-border-color">Declined</p>)
                                                                                                    :
                                                                                                    (<p className="status-waiting text-danger text-center custom-border-color">Closed</p>)
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                                :
                                                (
                                                    <div className="mx-auto text-center my-lg-5">
                                                        <p className="mb-3">No scrimmages found.</p>
                                                        {/* <p><a className="btn btn-secondary" href={"/scrimmagerequest/" + urlId}>Create Your First Scrimmage</a></p> */}
                                                        <p></p>
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="updateresult" tabIndex="-1" role="dialog" aria-labelledby="updateresult" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" className="close">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h4 className="custom-h4 text-center">Scrimmage update</h4>
                                <span className="d-flex text-center subtext">Congrats on completing the game. Please share your update.</span>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex justify-content-center">
                                            <StarRatingComponent
                                                name="rate1"
                                                starCount={5}
                                                value={this.state.rating}
                                                onStarHover={this.onStarHover}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="js-form-message mb-6 u-has-success">
                                            <label className="h6 small d-block text-uppercase">
                                                Special Instruction
                                            </label>

                                            <div className="js-focus-state input-group form">
                                                <textarea maxLength="150" className="form-control form__input" rows="6" name="gameFeedback" value={this.state.gameFeedback} required="" placeholder="Please type your instruction here" onKeyUp={this.handleType} id="charlimitupdate" onChange={this.handleChange}></textarea>
                                            </div>
                                            <span>Maximum 150 character.</span>
                                            <span className="text-muted float-right" id="charcountvalidate"></span>
                                            {
                                                this.state.descError ? <div style={{ color: "red" }}>{this.state.descError}</div> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-block btn-primary mt-5" onClick={() => this.handleScrimmageUpdate(this.props.scrimmageHistory)}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="calender" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Game Date</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {
                                    <Calendar value={this.state.date} minDate={this.state.date} maxDate={this.state.date} />
                                }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        teamDetailsData: state.TeamState,
        scrimmageRequestData: state.ScrimmageState,
        scrimmageHistory: state.ScrimmageState.scrimmageHistory,
        scrimmageFeedbackData: state.ScrimmageState.scrimmageFeedbackData
    }
};

const mapDispatchToProps = {
    requestScrimmageRequest,
    requestScrimmageInvitation,
    requestScrimmageConfirmation,
    requestScrimmageDecline,
    requestScrimmageAccept,
    requestScrimmageCancel,
    requestScrimmageHistory,
    requestScrimmageFeedback,
    requestScrimmageFeedbackData,
    requestScrimmageData,
    initialDefault
};

export default connect(mapStateToProps, mapDispatchToProps)(ScrimmageDetails)
