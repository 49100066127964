import React, { Component } from "react";
import { BrowserRouter, Switch } from "react-router-dom";

import AppRoute from './pages/component/AppRoute';
import AdminLayout from "./pages/component/layout/AdminLayout";
import PublicLayout from "./pages/component/layout/publicLayout";
import Login from "./pages/user/Login";
import Signup from "./pages/user/Signup";
import About from "./pages/AboutUs";
import Why from "./pages/WhyGameFinder";
import AddTeam from "./pages/team/AddTeam";
import YourTeam from "./pages/team/YourTeam";
import CreateTeam from "./pages/team/CreateTeam";
import CreateNewPass from "./pages/user/CreateNewPass";
import TeamDetails from "./pages/team/TeamDetails";
import ScrimmageRequest from "./pages/scrimmage/CreateScrimmageRequest";
import ScrimmageInvitation from "./pages/scrimmage/ScrimmageInvitation";
import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";

import "./App.css";
import "./Custom.css"
import 'react-toastify/dist/ReactToastify.css';
class App extends Component {

    render() {
        return (
                <>
                 <BrowserRouter>
                   <Switch>
                          <AppRoute exact path="/" layout ={PublicLayout} component={Home}/>
                          <AppRoute exact path="/login" layout ={PublicLayout} component={Login}/>
                          <AppRoute exact path="/signup" layout ={PublicLayout} component={Signup}/>
                          <AppRoute exact path="/aboutus" layout ={PublicLayout} component={About}/>
                          <AppRoute exact path="/why" layout ={PublicLayout} component={Why}/>
                          <AppRoute exact path="/pricing" layout ={PublicLayout} component={Pricing}/>
                          <AppRoute exact path="/contact" layout ={PublicLayout} component={Contact}/>
                          <AppRoute exact path="/addteam" layout ={AdminLayout} component={AddTeam}/>
                          <AppRoute exact path="/yourteam" layout ={AdminLayout} component={YourTeam}/>
                          <AppRoute exact path="/createteam" layout ={AdminLayout} component={CreateTeam}/>
                          <AppRoute exact path="/createteam/:id" layout ={AdminLayout} component={CreateTeam}/>
                          <AppRoute exact path="/createpass" layout ={PublicLayout} component={CreateNewPass}/>
                          <AppRoute exact path="/teamdetails/:id" layout ={AdminLayout} component={TeamDetails}/>
                          <AppRoute exact path="/scrimmagerequest/:id" layout ={AdminLayout} component={ScrimmageRequest}/>
                          <AppRoute exact path="/scrimmageinvitation/:id/:id" layout ={AdminLayout} component={ScrimmageInvitation}/>
                    </Switch>
                 </BrowserRouter> 
                </> 

        );
    }
}

export default App; 