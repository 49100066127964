import url from '../helpers/environment';
const axios = require('axios');
axios.defaults.baseURL = url


export const requestCreateLeague = (id,leagueData) => async dispatch => {
    axios.post('team/'+id+'/gamehistory',leagueData).then(response => {
        var createLeagueData = response.data;
        dispatch({ type: 'RECIVED_CREATE_LEAGUE_SUCCESS', createLeagueData });
    }).catch(error => {
        console.log(error)
        dispatch({ type: 'RECIVED_CREATE_LEAGUE_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_LEAGUE' });
}

export const requestEditLeague = (id,gameHistoryId,leagueData) => async dispatch => {
    axios.put('team/'+id+'/gamehistory/'+gameHistoryId,leagueData).then(response => {
        var editLeagueData = response.data;
        dispatch({ type: 'RECIVED_CREATE_LEAGUE_SUCCESS', editLeagueData });
    }).catch(error => {
        console.log(error)
        dispatch({ type: 'RECIVED_CREATE_LEAGUE_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_LEAGUE' });
}

export const requestLeagueData = (id) => async dispatch => {
    axios.get('team/'+id+'/gamehistory').then(response => {
        var getLeagueData = response.data;
        dispatch({ type: 'RECIVED_LEAGUE_DATA_SUCCESS', getLeagueData });
    }).catch(error => {
        console.log(error)
        dispatch({ type: 'RECIVED_LEAGUE_DATA_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_LEAGUE_DATA' });
}

export const requestDeleteLeague = (gameid,teamId) => async dispatch => {
    axios.delete('team/'+ teamId + '/gamehistory/' + gameid ).then(response => {
        var deleteLeagueData = response.data;
        dispatch({ type: 'RECIVED_DELETE_LEAGUE_DATA_SUCCESS', deleteLeagueData });
    }).catch(error => {
        console.log(error)
        dispatch({ type: 'RECIVED_DELETE_LEAGUE_DATA_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_DELETE_LEAGUE_DATA' });
}
export const initialDefault = () => async dispatch => {
    dispatch({ type: 'DEFAULT_SCRIMMAGE' });
}

