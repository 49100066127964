import React, { Component } from 'react';
import { FaMinusCircle } from 'react-icons/fa';
import SimpleReactValidator from 'simple-react-validator';
class AddAgeGroup extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      remove: false,
      age: null,
      minSkill: null,
      maxSkill: null,
      teamAgeGroup: ["6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18"],
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value })
  }

  render() {
    return (
      <>
        <div className="row">
        <div className="col-12 col-md-5">
            <div className="mb-7">
              <div className="js-form-message mb-6 u-has-success">
                <label className="d-block text-uppercase">
                  Opponent Team Level/Age Group
                <span className="text-danger">*</span>
                </label>
                <select className="custom-select" name="addage" value={this.props.addage} id={this.props.addage === "" ?"select-color":"" } aria-invalid="false" onChange={this.props.AddAgeGroup}>
                  <option selected="" value="">Select Team Level/Age Group</option>
		  <option disabled>_________</option>
		  <option disabled>SCHOOL TEAMS</option>
		  <option value="V" style={{ "color": "#000" }}>Varsity</option>
		  <option value="JV" style={{ "color": "#000" }}>Junior Varsity</option>
		  <option disabled>_________</option>
		  <option disabled>TRAVELLING TEAMS</option>
                  {
                    this.state.teamAgeGroup && this.state.teamAgeGroup.map((age, key) => {
                      return (
                        <option key={key} value={age} style={{"color":"#000"}}>{age} &amp; under</option>
                      )
                    })
                  }
                </select>
                <span className={this.props.addageError ?"additionError":""}>{this.props.addageError ? this.props.addageError : ""}</span>
                </div>
            </div>
          </div>
          <div className="col-10 col-md-6">
            <div className="mb-7">
              <label className="d-block text-uppercase">
                Team Skill Level
              <span className="text-danger">*</span>
              </label>
              <div className="row">
                <div className="col-6 col-md-6">
                  <select className="custom-select" name="addminSkill" value={this.props.addminSkill} id={this.props.addminSkill === null || this.props.addminSkill === "" ?"select-color":"" } aria-invalid="false" onChange={this.props.AddAgeGroup}>
                    <option value="">Select Min Skill</option>
                    <option value="1" style={{"color":"#000"}}>1</option>
                    <option value="2" style={{"color":"#000"}}>2</option>
                    <option value="3" style={{"color":"#000"}}>3</option>
                    <option value="4" style={{"color":"#000"}}>4</option>
                    <option value="5" style={{"color":"#000"}}>5</option>
                    <option value="6" style={{"color":"#000"}}>6</option>
                  </select>
                  <span className={this.props.addminSkillError ?"additionError":""}>{this.props.addminSkillError ? this.props.addminSkillError : ""}</span>
                </div>
                <div className="col-6 col-md-6">
                  <select className="custom-select" name="addmaxSkill" value={this.props.addmaxSkill} id={this.props.addmaxSkill === null || this.props.addmaxSkill === "" ?"select-color":"" } aria-invalid="false" onChange={this.props.AddAgeGroup}>
                    <option value="">Select Max Skill</option>
                    <option value="1" style={{"color":"#000"}}>1</option>
                    <option value="2" style={{"color":"#000"}}>2</option>
                    <option value="3" style={{"color":"#000"}}>3</option>
                    <option value="4" style={{"color":"#000"}}>4</option>
                    <option value="5" style={{"color":"#000"}}>5</option>
                    <option value="6" style={{"color":"#000"}}>6</option>
                  </select>
                  <span className={this.props.addmaxSkillError ?"additionError":""}>{this.props.addmaxSkillError ? this.props.addmaxSkillError : ""}</span>
                </div>
              </div>
            </div>
          </div>
        <div className="col-1">
          <label className="d-block text-uppercase">
               &nbsp;
          </label>
          <FaMinusCircle style={{color:"#f8aa4b",cursor: "pointer"}} onClick={this.props.removeAgeGroup}/> 
        </div>
        </div>
      </>
    );
  }
}

export default AddAgeGroup;