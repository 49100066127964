import React, { Component } from 'react'
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import { FaInfoCircle, FaArrowLeft } from 'react-icons/fa';
import { apiUrl } from '../../config';
import { toast } from "react-toastify";
import axios from "axios";
import Spinner from '../component/Spnner'
import SimpleReactValidator from 'simple-react-validator';
import LeagueHistory from '../component/LeagueHistory';
import Info from '../component/Info'
import CreateLeagueHistory from '../component/CreateLeagueHistory';
import { requestUploadImage } from '../../actions/imageActionCreator';
import { requestCreateTeam, requestGetTeamEditData, requestEditTeam, initialDefault } from '../../actions/createTeamActionCreator';
import { requestLeagueData, requestCreateLeague, requestDeleteLeague, requestEditLeague } from '../../actions/createLeagueActionCreator'
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;
var accessKey = localStorage.getItem("user_token");
axios.defaults.headers.common['Authorization'] = 'bearer ' + accessKey
const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={true}
      placement="top"
      key={index}
      tipProps={{ visible: true }}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>

  );
};

let monday = "";
let tuesday = "";
let wednesday = "";
let thursday = "";
let friday = "";
let satday = "";
let sunday = "";

class CreateTeam extends Component {
  constructor(props) {
    super(props);
    const year = (new Date('1/1/2018')).getFullYear();
    this.years = Array.from(new Array(5),(val, index) => index + year);
    axios.defaults.baseURL = apiUrl;
    this.validator = new SimpleReactValidator();
    this.state = {
      isLoading: false,
      file: "",
      imageData: "",
      teamName: "",
      teamNameError: "",
      zipcode: "",
      zipcodeError: "",
      teamDesc: "",
      descError: "",
      teamGender: "",
      sport: "",
      isGender: false,
      ageGroup: "",
      isAgeGroup: false,
      imgSrc: null,
      isActive: false,
      isDisabled: false,
      teamDetailSave: true,
      gameDetailSave: false,
      scrimmageSave: false,
      dateSelect: false,
      isAvail: false,
      isMiles: "yes",
      strengthValue: 1,
      travel: 0,
      travelError: "",
      availability: [],
      isMon: false,
      isTue: false,
      isWed: false,
      isThu: false,
      isFri: false,
      isSat: false,
      isSun: false,
      leagueName: "",
      leagueNameError: "",
      leagueYear: "",
      leagueYearError: "",
      season: "",
      seasonError: "",
      additionalDesc: "",
      result: "",
      resultError: "",
      leagueDataArr: [],
      gameHistory: [],
      gameHistoryId: "",
      leagueSeason: "",
      leagueDetails: []
    };
  }

  componentDidMount = () => {
    this.props.initialDefault();
    monday = document.getElementById("mon").innerHTML;
    tuesday = document.getElementById("tue").innerHTML;
    wednesday = document.getElementById("wed").innerHTML;
    thursday = document.getElementById("thu").innerHTML;
    friday = document.getElementById("fri").innerHTML;
    satday = document.getElementById("sat").innerHTML;
    sunday = document.getElementById("sun").innerHTML;

    if (this.props.match.params.id) {
      this.props.requestGetTeamEditData(this.props.match.params.id);
      this.props.requestLeagueData(this.props.match.params.id);
      setTimeout(() => {
        this.props.LeagueData && this.props.LeagueData.length !== 0 ? this.setState({ isAvail: true }) : this.setState({ isAvail: false });
        this.setState({
          teamName: this.props.GetTeamEditData && this.props.GetTeamEditData.name,
          teamDesc: this.props.GetTeamEditData && this.props.GetTeamEditData.description,
          imgSrc: this.props.GetTeamEditData && this.props.GetTeamEditData.imageUrl,
          zipcode: this.props.GetTeamEditData && this.props.GetTeamEditData.location.zipCode,
          teamGender: this.props.GetTeamEditData && this.props.GetTeamEditData.gender,
          sport: this.props.GetTeamEditData && this.props.GetTeamEditData.sport,
          travel: this.props.GetTeamEditData && this.props.GetTeamEditData.distance,
          strengthValue: this.props.GetTeamEditData && this.props.GetTeamEditData.skillLevel,
          ageGroup: this.props.GetTeamEditData && this.props.GetTeamEditData.ageGroup,
          availability: this.props.GetTeamEditData && this.props.GetTeamEditData.availability,
          isMon: false,
          isTue: false,
          isWed: false,
          isThu: false,
          isFri: false,
          isSat: false,
          isSun: false,
          isDisabled: true,
          leagueDataArr: this.props.LeagueData
        }, () => {
          if (this.state.travel === 0) {
            this.setState({
              isMiles: "no"
            })
          }
        })
        for (let key in this.props.GetTeamEditData && this.props.GetTeamEditData.availability) {
          switch (this.props.GetTeamEditData.availability[key]) {

            case "MON":
              this.setState({
                isMon: true
              })
              break;
            case "TUE":
              this.setState({
                isTue: true
              })
              break;
            case "WED":
              this.setState({
                isWed: true
              })
              break;
            case "THU":
              this.setState({
                isThu: true
              })
              break;
            case "FRI":
              this.setState({
                isFri: true
              })
              break;
            case "SAT":
              this.setState({
                isSat: true
              })
              break;
            case "SUN":
              this.setState({
                isSun: true
              })
              break;
            default:
          }

        }
      }, 1000);
    }
    else {
      this.setState({
        teamName: "",
        teamDesc: "",
        imgSrc: "",
        zipcode: "",
        teamGender: "",
        sport: "",
        travel: 0,
        strengthValue: 1,
        ageGroup: ""
      })
    }
  }


  handleUpload = (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({
        imgSrc: URL.createObjectURL(event.target.files[0]),
        isActive: true,
        file: event.target.files[0]
      }, () => {
        if (this.state.file) {
          this.props.requestUploadImage(this.state.file);
        }
      })
    }
    else {
      this.setState({
        imgSrc: "",
        isActive: false
      })
    }
  }

  componentWillReceiveProps = (nextProps) => {

    if (nextProps.TeamCreate.status === 3) {
      this.props.history.push("/yourteam")
    }
    if (nextProps.TeamCreate.status === 2) {
      this.setState({ isLoading: false })
    }
    if (nextProps.LeagueStatus === 3) {
      window.location.reload();
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (this.state.leagueName !== "") {
        this.setState({
          leagueNameError: ""
        })
      }
      if (this.state.leagueSeason !== "") {
        this.setState({
          leagueSeasonError: ""
        })
      }
      if (this.state.leagueYear !== "") {
        this.setState({
          leagueYearError: ""
        })
      }
      if (this.state.result !== "") {
        this.setState({
          resultError: ""
        })
      }
    });

    switch (name) {
      case 'teamGender':
        this.setState({
          teamGender: document.getElementById("gender").value
        });
        break;
      case 'sport':
        this.setState({
          sport: document.getElementById("sport").value
        });
        break;
      case 'ageGroup':
        this.setState({
          ageGroup: document.getElementById("agegroup").value
        });
        break;
      case 'leagueSeason':
        this.setState({
          leagueSeason: document.getElementById("leagueseason").value
        });
        break;
      case 'leagueYear':
        this.setState({
          leagueYear: document.getElementById("leagueyear").value
        })
        break;
      default:
        break;
    }
  }

  leagueYearChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value })
  }

  handleSelectDate = (day) => {
    switch (day) {
      case "MON":
        this.setState({
          isMon: !this.state.isMon
        })
        break;
      case "TUE":
        this.setState({
          isTue: !this.state.isTue
        })
        break;
      case "WED":
        this.setState({
          isWed: !this.state.isWed
        })
        break;
      case "THU":
        this.setState({
          isThu: !this.state.isThu
        })
        break;
      case "FRI":
        this.setState({
          isFri: !this.state.isFri
        })
        break;
      case "SAT":
        this.setState({
          isSat: !this.state.isSat
        })
        break;
      case "SUN":
        this.setState({
          isSun: !this.state.isSun
        })
        break;
      default:
    }
    let index = this.state.availability.findIndex(x => x === day)
    if (index === -1) {
      this.state.availability.push(day);
    } else {
      this.state.availability && this.state.availability.splice(index, 1)
    }
  }

  handleClickTeamDetails = () => {
    const { teamName, zipcode, teamDesc, teamGender, sport, ageGroup } = this.state;
    if (teamName && zipcode && teamGender && sport && ageGroup) {
      this.setState({
        teamDetailSave: false,
        gameDetailSave: true
      })
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  handleBackTeam = () => {
    this.setState({
      teamDetailSave: true,
      gameDetailSave: false
    })
  }

  handleSaveCreateLeague = (e) => {
    e.preventDefault()
    const { leagueName, leagueSeason, leagueYear, result } = this.state
    if (leagueName && leagueSeason && leagueYear && result) {
      toast('League Added', { type: 'success' })
      this.setState({
        gameHistory: [...this.state.gameHistory, {
          name: this.state.leagueName,
          season: this.state.leagueSeason,
          year: this.state.leagueYear,
          description: this.state.additionalDesc,
          result: this.state.result
        }]
      })
      if (this.props.match.params.id) {
        const data = {
          name: this.state.leagueName,
          season: this.state.leagueSeason,
          year: this.state.leagueYear,
          description: this.state.additionalDesc,
          result: this.state.result
        }
        if (this.state.gameHistoryId) {
          this.props.requestEditLeague(this.props.match.params.id, this.state.gameHistoryId, data)
        }
        else {
          this.props.requestCreateLeague(this.props.match.params.id, data)
        }
      }
      else {
        this.setState({
          leagueName: "",
          leagueSeason: "",
          leagueYear: "",
          additionalDesc: "",
          result: ""
        })
      }

    } else if (this.state.leagueName === "") {
      this.setState({
        leagueNameError: "This field is required"
      })
    }
    else if (this.state.leagueSeason === "") {
      this.setState({
        leagueSeasonError: "This field is required"
      })
    }
    else if (this.state.leagueYear === "") {
      this.setState({
        leagueYearError: "This field is required"
      })
    }
    else if (this.state.result === "") {
      this.setState({
        resultError: "This field is required"
      })
    }

  }

  handleCreateTeam = () => {
    if (this.state.isMiles === "no") {
      this.setState({
        travel: "0"
      }, () => {
        this.createTeam();
      })
    } else {
      this.setState({
        travel: this.state.travel
      }, () => {
        this.createTeam();
      })
    }
  }

  createTeam = () => {
    const { ageGroup } = this.state;
    this.setState({
      imageData: this.props.image.ImageState.image && this.props.image.ImageState.image.toString(),
    }, () => {
      if (this.props.match.params.id) {
        let Editdata = this.props.GetTeamEditData;
        const editData = {
          name: this.state.teamName,
          description: this.state.teamDesc,
          imageUrl: this.props.imageStatus === 3 ? this.state.imageData && this.state.imageData.toString() : this.props.GetTeamEditData && this.props.GetTeamEditData.imageUrl,
          coverImage: Editdata && Editdata.coverImage,
          location: {
            zipcode: this.state.zipcode
          },
          gender: this.state.teamGender,
          sport: this.state.sport,
          distance: this.state.travel,
          skillLevel: this.state.strengthValue,
          ageGroup: this.state.ageGroup,
          availability: this.state.availability,
        }
        if (editData.distance !== 0) {
          this.setState({ isLoading: true, })
          this.props.requestEditTeam(editData, this.props.match.params.id)
        }
        else {
          this.setState({
            travelError: "Distance should be more than 0"
          })
        }
      }

      else {
        const createTeamData = {
          name: this.state.teamName,
          description: this.state.teamDesc,
          imageUrl: this.props.imageStatus === 3 ? this.state.imageData && this.state.imageData.toString() : '',
          location: {
            zipcode: this.state.zipcode
          },
          gender: this.state.teamGender,
          sport: this.state.sport,
          distance: this.state.travel,
          skillLevel: this.state.strengthValue,
          ageGroup: this.state.ageGroup,
          availability: this.state.availability,
          gameHistory: this.state.gameHistory
        }
        if (createTeamData.distance !== 0) {
          this.setState({ isLoading: true, })
          this.props.requestCreateTeam(createTeamData)
        }
        else {
          this.setState({
            travelError: "Distance should be more than 0"
          })
        }
      }
    })

  }

  handleType = () => {
    var total = document.getElementById("char").value;
    document.getElementById("charcount").innerHTML = total.length + "/300";
  }
  handleDesc = () => {
    var totalChar = document.getElementById("desc").value;
    document.getElementById("charcountvalidate").innerHTML = totalChar.length + "/300";
  }

  handleRadioChange = (e) => {
    this.setState({
      isMiles: e.target.value
    })
  }

  getStrength = (e) => {
    this.setState({
      strengthValue: e
    })
  }
  getTravelDistance = (e) => {
    this.setState({
      travel: e
    })
  }

  handleDeleteLeague = (gameid) => {
    let teamId = window.location.pathname.split('/')[2];
    this.props.requestDeleteLeague(gameid, teamId)
  }

  handleLeagueId = (data) => {
    this.setState({
      leagueDetails: data
    })
  }

  handleRemoveLeague = (data) => {
    const items = this.state.gameHistory.filter((item) => {
      return (
        item !== data
      )
    })
    this.setState({
      gameHistory: items
    })
  }

  handleGetLeagueData = (data) => {
    this.setState({
      leagueName: data.name,
      leagueYear: data.year,
      leagueSeason: data.season,
      result: data.result,
      additionalDesc: data.description,
      gameHistoryId: data.id
    })
  }

  render() {
    const agegroup = ["6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18"]
    const { leagueName, leagueSeason, leagueYear, result } = this.state
    let data = this.props.GetTeamEditData;
    return (
      <div className="container space-2">
        <div className="row">
          <div className="col">
            <Spinner display={this.state.isLoading}>
              <form className="js-validate js-step-form pr-lg-4" data-progress-id="#stepFormProgress" data-steps-id="#stepFormContent" noValidate="noValidate">
                <ul id="stepFormProgress" className="js-step-progress list-inline u-shopping-cart-step-form mb-4">
                  <li className={this.state.teamDetailSave ? "list-inline-item u-shopping-cart-step-form__item mb-5 u-checked active" : "list-inline-item u-shopping-cart-step-form__item mb-5 u-checked"} onClick={this.handleBackTeam}>
                    <img className="max-width-10 mx-auto mb-2" src={this.state.teamDetailSave ? "/img/create-team-form/team.png" : "/img/create-team-form/teambw.png"} alt="Coaches" />
                    <span className="u-shopping-cart-step-form__title">Team Details</span>
                  </li>
                  <li className={this.state.gameDetailSave ? "list-inline-item u-shopping-cart-step-form__item mb-5 u-checked active" : "list-inline-item u-shopping-cart-step-form__item mb-5 u-checked"} onClick={this.handleClickTeamDetails}>
                    <img className="max-width-10 mx-auto mb-2" src={this.state.gameDetailSave ? "/img/create-team-form/trophy.png" : "/img/create-team-form/trophybw.png"} alt="Coaches" />
                    <span className="u-shopping-cart-step-form__title">Scrimmage Details</span>
                  </li>
                </ul>
                <div id="stepFormContent">
                  <div id="customerInfoStep" className={this.state.teamDetailSave ? "block" : "none"}>
                    <div className="row">
                      <div className="col-md-2">
                        <label className="upload d-flex m-auto cursor-pointer" style={this.state.imgSrc ? {} : { "background-color": "#cdcdcd" }}>
                          <input type="file" accept="image/*" onChange={this.handleUpload} />
                          <div className={this.state.imgSrc ? "m-auto hide" : "m-auto"}>
                            <div>
                              <div className="d-flex">
                                <img src="/img/create-team-form/upload.png" className="m-auto" alt="upload" />
                              </div>
                            </div>
                          </div>
                          <img src={this.state.imgSrc} className={this.state.isActive ? "img-fluid upload-image" : "img-fluid"} />
                        </label>
                        <p className="font-size-13 text-center">Upload Team Logo</p>
                      </div>

                      <div className="col-md-10">
                        <div className="row">
                          <div className="w-100"></div>
                          <div className="col-md-6">
                            <div className="js-form-message mb-6 u-has-success">
                              <label className="d-block text-uppercase">
                                Team Name
                              <span className="text-danger">*</span>
                              </label>
                              <div className="js-focus-state input-group form">
                                <input className="form-control form__input" name="teamName" value={this.state.teamName} type="text" required="" placeholder="Your team name" onChange={this.handleChange} />
                              </div>
                              {this.validator.message('', this.state.teamName, 'required')}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="js-form-message mb-6 u-has-success">
                              <label className="d-block text-uppercase">
                                Team Gender
                            <span className="text-danger">*</span>
                              </label>
                              <select className={this.state.teamGender === "" ? "select-color custom-select" : "custom-select"} aria-invalid="false" required="required" name="teamGender" id="gender" onChange={this.handleChange} value={this.state.teamGender}>
                                <option value="">Select gender</option>
                                <option value="male" style={{ "color": "#000" }}>Male</option>
                                <option value="female" style={{ "color": "#000" }}>Female</option>
                                <option value="co-ed" style={{ "color": "#000" }}>Co-ed</option>
                              </select>
                              {this.validator.message('', this.state.teamGender, 'required')}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="js-form-message mb-6 u-has-success">
                              <label className="d-block text-uppercase">
                                SPORT
                              <span className="text-danger">*</span>
                              </label>
                              {this.state.isDisabled ?
                                <input className="form-control form__input" type="text" value={this.state.sport} disabled /> :
                                <select className={this.state.sport == "" ? "select-color custom-select" : "custom-select"} aria-invalid="false" required="required" name="sport" id="sport" onChange={this.handleChange} value={this.state.sport}>
                                  <option value="">Select sport</option>
                                  <option value="Basketball" style={{ "color": "#000" }}>Basketball</option>
                                  <option value="Baseball" style={{ "color": "#000" }}>Baseball</option>
                                </select>}
                              <span className="text-muted font-size-13">Note: Other sports options coming soon</span>
                              <div id="phone-error" className="invalid-feedback" style={{ display: "none" }}></div></div>
                          </div>
                          <div className="col-md-6">
                            <div className="js-form-message mb-6 u-has-success">
                              <label className="d-block text-uppercase">
                                My Home Zip Code
                              <span className="text-danger">*</span>
                              </label>

                              <div className="js-focus-state input-group form">
                                <input className="form-control form__input" name="zipcode" value={this.state.zipcode} type="number" min="0" required="" placeholder="11002" onChange={this.handleChange} />
                              </div>
                              {this.validator.message('', this.state.zipcode, 'required')}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="js-form-message mb-6 u-has-success">
                              <label className="d-block text-uppercase d-flex">
                                Team Skill Level
                                <span className="text-danger">*</span>
                                &nbsp; <FaInfoCircle data-toggle="modal" data-target="#exampleModalCenterInfo" />
                              </label>
                              <div className="d-flex">
                                <Slider min={1} max={6} value={this.state.strengthValue} defaultValue={this.state.strengthValue} marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6 }} step={20} toolTipVisibleAlways={true} onChange={this.getStrength} />
                              </div>
                              <div id="email-error" className="invalid-feedback" style={{ display: "none" }}></div></div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-12">
                                <label className="d-block text-uppercase d-flex">
                                  My Team Level/Age Group
                                  <span className="text-danger">*</span>
                                  <div className="custom-p">
                                  </div>
                                </label>
                              </div>
                              <div className="col-12 age-group">
                                <select className={this.state.ageGroup === "" ? "select-color custom-select" : "custom-select"} aria-invalid="false" onChange={this.handleChange} name="ageGroup" value={this.state.ageGroup} id="agegroup">
				  <option value="">Select Team Level/Age Group</option>
				  <option disabled>_________</option>
				  <option disabled>SCHOOL TEAMS</option>
				  <option value="V" style={{ "color": "#000" }}>Varsity</option>
				  <option value="JV" style={{ "color": "#000" }}>Junior Varsity</option>
				  <option disabled>_________</option>
				  <option disabled>TRAVELLING TEAMS</option>
                                  {
                                    agegroup && agegroup.map((data) => {
                                      return (
                                        <option value={`${data} & under`} style={{ "color": "#000" }}>{data} &amp; under</option>
                                      )
                                    })
                                  }
                                </select>
                                {this.validator.message('', this.state.ageGroup, 'required')}
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mt-3">
                            <div className="mb-7">
                              <div className="js-form-message mb-6 u-has-success">
                                <label className="d-block text-uppercase">
                                  Team Description
                                </label>
                                <div className="js-focus-state input-group form">
                                  <textarea maxlength="300" className="form-control form__input" rows="6" value={this.state.teamDesc} name="teamDesc" required="" placeholder="We are a newly formed team... or Our team has been together for several years..." onKeyUp={this.handleDesc} id="desc" onChange={this.handleChange}></textarea>
                                </div>
                                <span className="text-muted font-size-13 text-lh-sm">Maximum 300 characters</span>
                                <span className="text-muted font-size-13 float-right" id="charcountvalidate"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="clearfix" />
                    <div className="row">
                      <div className="col-12 mb-4 d-md-flex w-100">
                        <h2 className="font-weight-bold">League/Tournament History</h2>
                        <button type="button" className="btn btn-primary deleteleague my-md-2 ml-auto" data-toggle="modal" data-target="#leagueHistory">Add Tournament</button>
                      </div>
                      <div className="col-md-12">
                        {
                          this.state.gameHistory && this.state.gameHistory.length !== 0 ?
                            <>
                              <table class="table table-striped">
                                <thead>
                                  <tr>
                                    <th scope="col">Tournament Name</th>
                                    <th scope="col">Result</th>
                                    <th scope="col">Season</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    this.state.gameHistory && this.state.gameHistory.map((data, key) => {
                                      return (
                                        <>
                                          <tr>
                                            <td>{data.name}</td>
                                            <td>{data.result}</td>
                                            <td>{data.season}, {data.year}</td>
                                            <td><img src="/img/create-team/delete.png" className="ml-auto mr-2" alt="delete" data-toggle="modal" data-target="#remove-modal" onClick={() => this.handleLeagueId(data)} /></td>
                                          </tr>

                                        </>
                                      )

                                    })
                                  }
                                </tbody>
                              </table>
                            </>
                            :
                            (null)
                        }

                        {
                          this.state.isAvail ?
                            (<LeagueHistory GetLeagueData={this.handleGetLeagueData} />)
                            :
                            (null)
                        }
                      </div>
                    </div>
                    <hr className="clearfix" />
                    <div className="d-sm-flex justify-content-sm-between align-items-sm-center mt-5">
                      <Link to={this.props.teamDetailsData.teamData && this.props.teamDetailsData.teamData.length === 0 ? "/addteam" : "/yourteam"} className="d-md-block mb-3 mb-sm-0 back">
                      </Link>
                      <button type="button" className="btn btn-primary float-right float-md-left custom-btn-color font-weight-bold" onClick={this.handleClickTeamDetails}>Save &amp; Proceed</button>
                    </div>
                  </div>

                  <div id="shippingStep" className={this.state.gameDetailSave ? "block" : "none"}>
                    <div className="row mb-6 mx-auto">
                      <div className="col-lg-8 mx-auto">
                        <label className="d-block text-uppercase">
                          Distance willing to travel
                        <span className="text-danger">*</span>
                        </label>
                        <div className="row">
                          <div className="col-6">

                            <div className="custom-control custom-radio custom-control-inline form-selector-modern">
                              <input type="radio" id="miles" className="custom-control-input form-selector-modern__input" name="productShippingSelector" value="yes" onClick={this.handleRadioChange} />
                              <label className={`custom-control-label form-selector-modern__label ${this.state.isMiles === 'yes' ? 'check' : ''}`} for="miles">
                                <span className="d-block h6 mb-1">Miles</span>
                              </label>
                            </div>
                          </div>
                          <div className="col-6">

                            <div className="custom-control custom-radio custom-control-inline form-selector-modern">
                              <input type="radio" id="any" className="custom-control-input form-selector-modern__input" name="productShippingSelector" value="no" onClick={this.handleRadioChange} />
                              <label className={`custom-control-label form-selector-modern__label ${this.state.isMiles === 'no' ? 'check' : ''}`} for="any">
                                <span className="d-block h6 mb-1">Any</span>
                              </label>
                            </div>
                          </div>
                          {
                            this.state.isMiles === "yes" ?
                              <div className="col-12">
                                <Slider min={0} max={100} value={this.state.travel} defaultValue={this.state.travel} step={10} handle={handle} toolTipVisible={true} onChange={this.getTravelDistance} tipProps={{ visible: true }} />
                                <span>0 miles</span>
                                <span className="float-right">100 miles</span>
                                {
                                  this.state.travelError ? <div style={{ color: "red" }}>{this.state.travelError}</div> : null
                                }
                              </div>
                              :
                              <div></div>
                          }
                        </div>
                      </div>
                      <div className="col-lg-8 mx-auto">
                        <div className="row">
                          <div className="col-12 mt-9">
                            <label className="d-block text-uppercase">
                              Available for Scrimmage
                            </label>
                            <ul className="d-md-flex day pl-0 pt-0">
                              <div className={`date d-flex ${this.state.isSun === true ? "custom-bg-color" : ""} rounded w-md-100 mx-1 mb-2 mb-md-0`} onClick={() => this.handleSelectDate("SUN")}>
                                <li className="m-auto"><p className={`my-1 mx-auto ${this.state.isSun === true ? "text-white" : "text-dark"}`} id="sun">SUN</p></li>
                              </div>
                              <div className={`date d-flex ${this.state.isMon === true ? "custom-bg-color" : ""} rounded w-md-100 mx-1 mb-2 mb-md-0`} onClick={() => this.handleSelectDate("MON")}>
                                <li className="m-auto"><p className={`my-1 mx-auto ${this.state.isMon === true ? "text-white" : "text-dark"}`} id="mon">MON</p></li>
                              </div>
                              <div className={`date d-flex ${this.state.isTue === true ? "custom-bg-color" : ""} rounded w-md-100 mx-1 mb-2 mb-md-0`} onClick={() => this.handleSelectDate("TUE")}>
                                <li className="m-auto"><p className={`my-1 mx-auto ${this.state.isTue === true ? "text-white" : "text-dark"}`} id="tue">TUE</p></li>
                              </div>
                              <div className={`date d-flex ${this.state.isWed === true ? "custom-bg-color" : ""} rounded w-md-100 mx-1 mb-2 mb-md-0`} onClick={() => this.handleSelectDate("WED")}>
                                <li className="m-auto"><p className={`my-1 mx-auto ${this.state.isWed === true ? "text-white" : "text-dark"}`} id="wed">WED</p></li>
                              </div>
                              <div className={`date d-flex ${this.state.isThu === true ? "custom-bg-color" : ""} rounded w-md-100 mx-1 mb-2 mb-md-0`} onClick={() => this.handleSelectDate("THU")}>
                                <li className="m-auto"><p className={`my-1 mx-auto ${this.state.isThu === true ? "text-white" : "text-dark"}`} id="thu">THU</p></li>
                              </div>
                              <div className={`date d-flex ${this.state.isFri === true ? "custom-bg-color" : ""} rounded w-md-100 mx-1 mb-2 mb-md-0`} onClick={() => this.handleSelectDate("FRI")}>
                                <li className="m-auto"><p className={`my-1 mx-auto ${this.state.isFri === true ? "text-white" : "text-dark"}`} id="fri">FRI</p></li>
                              </div>
                              <div className={`date d-flex ${this.state.isSat === true ? "custom-bg-color" : ""} rounded w-md-100 mx-1 mb-2 mb-md-0`} onClick={() => this.handleSelectDate("SAT")}>
                                <li className="m-auto"><p className={`my-1 mx-auto ${this.state.isSat === true ? "text-white" : "text-dark"}`} id="sat">SAT</p></li>
                              </div>
                            </ul>
                            <ul className="invisible d-md-flex day pl-0 mb-0">
                              <li className={`day-checkbox d-flex ${this.state.isSun === true ? "custom-bg-color" : ""} rounded w-md-75 mx-1 mb-2 mb-md-0`} onClick={() => this.handleSelectDate("SUN")}>
                                <input type="checkbox" className="custom-control-input day-checkbox" checked={this.state.isSun === true ? true : false} />
                              </li>
                              <li className={`day-checkbox d-flex ${this.state.isMon === true ? "custom-bg-color" : ""} rounded w-md-75 mx-1 mb-2 mb-md-0`} onClick={() => this.handleSelectDate("MON")}>
                                <input type="checkbox" className="custom-control-input day-checkbox" checked={this.state.isMon === true ? true : false} />
                              </li>
                              <li className={`day-checkbox d-flex ${this.state.isTue === true ? "custom-bg-color" : ""} rounded w-md-75 mx-1 mb-2 mb-md-0`} onClick={() => this.handleSelectDate("TUE")}>
                                <input type="checkbox" className="custom-control-input day-checkbox" checked={this.state.isTue === true ? true : false} />
                              </li>
                              <li className={`day-checkbox d-flex ${this.state.isWed === true ? "custom-bg-color" : ""} rounded w-md-75 mx-1 mb-2 mb-md-0`} onClick={() => this.handleSelectDate("WED")}>
                                <input type="checkbox" className="custom-control-input day-checkbox" checked={this.state.isWed === true ? true : false} />
                              </li>
                              <li className={`day-checkbox d-flex ${this.state.isThu === true ? "custom-bg-color" : ""} rounded w-md-75 mx-1 mb-2 mb-md-0`} onClick={() => this.handleSelectDate("THU")}>
                                <input type="checkbox" className="custom-control-input day-checkbox" checked={this.state.isThu === true ? true : false} />
                              </li>
                              <li className={`day-checkbox d-flex ${this.state.isFri === true ? "custom-bg-color" : ""} rounded w-md-75 mx-1 mb-2 mb-md-0`} onClick={() => this.handleSelectDate("FRI")}>
                                <input type="checkbox" className="custom-control-input day-checkbox" checked={this.state.isFri === true ? true : false} />
                              </li>
                              <li className={`day-checkbox d-flex ${this.state.isSat === true ? "custom-bg-color" : ""} rounded w-md-75 mx-1 mb-2 mb-md-0`} onClick={() => this.handleSelectDate("SAT")}>
                                <input type="checkbox" className="custom-control-input day-checkbox" checked={this.state.isSat === true ? true : false} />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="clearfix" />
                    <div className="d-flex justify-content-sm-between align-items-sm-center">
                      <div className="d-md-block mb-3 mb-sm-0 custom-color cursor-pointer" data-previous-step="#customerInfoStep" onClick={this.handleBackTeam}>
                        <FaArrowLeft /> &nbsp;
                        Team Details
                    </div>
                      <button type="button" className="btn btn-primary custom-btn-color ml-auto font-weight-bold" onClick={this.handleCreateTeam}>Save</button>
                    </div>
                  </div>
                </div>
              </form>
            </Spinner>
          </div>
        </div>

        <div className="modal fade" id="leagueHistory" tabIndex="-1" role="dialog" aria-labelledby="leagueHistory" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-dialog-view-history" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="p-md-5 bg-white rounded">
                  <form className="js-validate" noValidate="noValidate">
                    <div id="signin" data-target-group="idForm">
                      <header className="text-center mb-5">
                        <h2 className="mb-0 font-weight-bold">League/Tournament History</h2>
                        <p>Provide your league and tournament details</p>
                      </header>
                      <div className="container">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="js-form-message u-has-success">
                              <label className="d-block text-uppercase">
                                League/Tournament Name
                                <span className="text-danger">*</span>
                              </label>
                              <input className="form-control form__input" type="text" name="leagueName" value={this.state.leagueName} placeholder="Champion Trophy" onChange={this.handleChange} />
                              <label className={this.state.leagueNameError ? "srv-validation-message" : ""}>{this.state.leagueNameError ? this.state.leagueNameError : ""}</label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="js-form-message u-has-success">
                              <label className="d-block text-uppercase">
                                Season
                                <span className="text-danger">*</span>
                              </label>
                              <select className={this.state.leagueSeason === "" ? "select-color custom-select" : "custom-select"} aria-invalid="false" required="required" name="leagueSeason" id="leagueseason" onChange={this.handleChange} value={this.state.leagueSeason}>
                                <option value="">Select Season</option>
                                <option value="Winter" style={{ "color": "#000" }}>Winter</option>
                                <option value="Spring" style={{ "color": "#000" }}>Spring</option>
                                <option value="Summer" style={{ "color": "#000" }}>Summer</option>
                                <option value="Fall" style={{ "color": "#000" }}>Fall</option>
                              </select>
                              <label className={this.state.leagueSeasonError ? "srv-validation-message" : ""}>{this.state.leagueSeasonError ? this.state.leagueSeasonError : ""}</label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="js-form-message u-has-success">
                              <label className="d-block text-uppercase">
                                League/Tournament Year
                                <span className="text-danger">*</span>
                              </label>
                              <select className={this.state.leagueYear === "" ? "select-color custom-select" : "custom-select"} aria-invalid="false" required="required" name="leagueYear" id="leagueyear" onChange={this.handleChange} value={this.state.leagueYear}>
			      {
			        this.years.map((year, index) => {
				  return <option key={`year${index}`} value={year} style={{ "color": "#000" }}>{year}</option>
			        })
			      }
                              </select>
                              <label className={this.state.leagueYearError ? "srv-validation-message" : ""}>{this.state.leagueYearError ? this.state.leagueYearError : ""}</label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="js-form-message u-has-success">
                              <label className="d-block text-uppercase">
                                Result
                                <span className="text-danger">*</span>
                              </label>
                              <input className="form-control form__input" type="text" name="result" value={this.state.result} placeholder="Winner" onChange={this.handleChange} />
                              <label className={this.state.resultError ? "srv-validation-message" : ""}>{this.state.resultError ? this.state.resultError : ""}</label>
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="js-form-message u-has-success">
                              <label className="d-block text-uppercase">
                                Additional Details
                                </label>
                              <div className="js-focus-state input-group form">
                                <textarea maxLength="300" className="form-control form__input" rows="6" name="additionalDesc" value={this.state.additionalDesc} required="" placeholder="Please type your instruction here" aria-label="How can we help you?" data-msg="Please enter a reason." onKeyUp={this.handleType} id="char" onChange={this.handleChange}></textarea>
                              </div>
                              <span className="text-muted font-size-13 text-lh-sm">Maximum 300 characters</span>
                              <span className="text-muted float-right" id="charcount"></span>
                              <div id="email-error" className="invalid-feedback" style={{ display: "none" }}></div></div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="mb-3">
                        <button type="submit" className="btn btn-block btn-primary w-50 m-auto font-weight-bold" data-dismiss={leagueName && leagueSeason && leagueYear && result ? "modal" : ""} onClick={this.handleSaveCreateLeague}>Save</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="exampleModalCenterInfo" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <section className="modal-body px-3 my-3">
                  <Info />
                </section>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="remove-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">{this.state.leagueDetails.name}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                Are you sure you want to delete this league?
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary font-weight-bold" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary font-weight-bold" data-dismiss="modal" onClick={() => this.handleRemoveLeague(this.state.leagueDetails)}>Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    image: state,
    teamDetailsData: state.TeamState,
    TeamCreate: state.CreateTeamState,
    GetTeamEditData: state.CreateTeamState.getTeamEditData,
    LeagueData: state.LeagueDataState.getLeagueData,
    LeagueStatus: state.LeagueDataState.status,
    isLoading: state.CreateTeamState.isLoading,
    imageStatus: state.ImageState.status
  }
};

const mapDispatchToProps = {
  requestUploadImage,
  requestCreateTeam,
  requestGetTeamEditData,
  requestEditTeam,
  requestLeagueData,
  requestCreateLeague,
  requestDeleteLeague,
  requestEditLeague,
  initialDefault
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateTeam)
