import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { apiUrl } from '../../config';
import Spinner from '../component/Spnner'
import axios from "axios";
import { emailValidate } from "../../Util"
import SimpleReactValidator from 'simple-react-validator';
import { requestLogin, requestResetPassword } from '../../actions/loginActionCreator';
import { initialDefault } from '../../actions/loginActionCreator'
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

class Login extends Component {
  constructor(props) {
    super(props);
    axios.defaults.baseURL = apiUrl;
    this.validator = new SimpleReactValidator();
    this.state = {
      class: "u-video-player",
      resetPassEmail: "",
      resetEmailError: "",
      email: "",
      password: "",
      passwordError: "",
      emailError: "",
      data:"",
      rememberPass:false
    };
  }

componentDidMount(){
  window.localStorage.clear();
}

  componentWillReceiveProps(nextProps) {
    window.localStorage.clear();
    if (nextProps.err.token) {
      localStorage.setItem('user_token', nextProps.err.token.accessToken)
      localStorage.setItem('user_name', nextProps.err.token.userName)
      localStorage.setItem('user_id', nextProps.err.token.userId)
    }

    if(nextProps.forgotPassStatus.TokenState.forgotPassStatus === 200){
      toast('Success',{type:'success'})
      //window.location.href="/login";
    }

    if(nextProps.err.teamData && nextProps.err.teamData.length === 0){
      //toast('Success',{type:'success'})
      this.props.history.push("/addteam")
    }
    else if(nextProps.err.teamData && nextProps.err.teamData.length > 0){
      //toast('Success',{type:'success'})
      this.props.history.push("/yourteam")
    }
    if(nextProps.err.status === 2){
      this.setState({
        email: "",
        password: "",
      })
      switch(nextProps.err && nextProps.err.err){
        case "Email is Incorrect." : 
        this.setState({
          emailError: nextProps.err.err
        })
        toast('Email is Incorrect',{type:'error'})
        break;
        case "Password is Incorrect." : 
        this.setState({
          passwordError: nextProps.err.err
        })
        toast('Password is Incorrect',{type:'error'})
        break;
        default: 
        toast('Could not login',{type:'error'})
      }
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value })
  }

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.email) {
      const isValidEmail = emailValidate(this.state.email);
      if (isValidEmail) {
        this.setState({
          emailError:""
        })
        const loginData = {
          email: this.state.email,
          password: this.state.password
        }
        this.props.requestLogin(loginData)
      }
      else {
        this.setState({
          emailError:"Invalid Email"
        })
      }
    }
    else {
      this.setState({
        emailError: "This field is required"
      })
      this.validator.showMessages();
      this.forceUpdate();
    }
    if (this.state.password === "") {
      this.setState({
        passwordError: "This field is required"
      })
    }
    else {
      this.setState({
        passwordError: ""
      })
    }
  };

  handleResetPass = () => {
    if (this.state.resetPassEmail) {
      const registerdEmail = emailValidate(this.state.resetPassEmail);
      if (registerdEmail) {
        this.setState({ resetEmailError: "" });
        const emailData = {
          email: this.state.resetPassEmail
        }
        this.props.requestResetPassword(emailData)
      }
      else {
        this.setState({ resetEmailError: "Please enter valid email address." });
          }
    }
    else {
      this.setState({
        resetEmailError: "This field is required"
      })
    }
  }

  handleForgotPass=()=>{
    this.props.initialDefault()
  }
    
  render() {
    return (
      <div>
        <div className="gradient-overlay-half-dark-v3 bg-img-hero login-background">
          <div className="align-items-lg-center height-100vh">
            <div className="container space-2">
              <div className="row justify-content-lg-between align-items-lg-center">
                <div className="col-lg-7 m-auto">
                <Spinner display={this.props.isLoading}>
                  <div className="bg-white shadow-sm rounded p-6">
                    <form className="js-validate" noValidate="noValidate" onSubmit={this.handleSubmit}>
                      <div className="mb-4">
                        <h2 className="font-weight-bold text-center">Log In to Your Account</h2>
                      </div>
                      <div className="js-form-message mb-3">
                        <div className={this.state.emailError ? "js-focus-state input-group input-group form error":"js-focus-state input-group input-group form"}>
                          <input type="email" className={this.state.emailError ? "form-control form__input" : "form-control form__input"} name="email" value={this.state.email} placeholder="Enter Your Email Address" aria-label="Enter Your Email Address" onChange={this.handleChange} />
                        </div>
                        {this.validator.message('', this.state.email, 'required')}
                      </div>

                      <div className="js-form-message mb-3">
                        <div className={this.state.passwordError ? "js-focus-state input-group input-group form error":"js-focus-state input-group input-group form"}>
                          <input type="password" className="form-control form__input" name="password" value={this.state.password} placeholder="Enter Your Password" aria-label="Enter Your Password" onChange={this.handleChange} />
                        </div>
                        {
                          this.state.passwordError ? <div style={{ color: "red" }}>{this.state.passwordError}</div> : null
                        }
                      </div>

                      <div className="row mb-3">
                        <div className="col-6">
                          <div className="custom-control custom-checkbox d-flex align-items-center text-muted">
                            <input type="checkbox" className="custom-control-input" id="rememberMeCheckbox"/>
                            <label className="custom-control-label" for="rememberMeCheckbox">
                              Remember Me
                            </label>
                          </div>
                        </div>

                        <div className="col-6 text-right">
                          <a className="float-right forgot-pass" data-toggle="modal" href="#exampleModalCenter" onClick={this.handleForgotPass}>Forgot Password?</a>
                        </div>
                      </div>
                      <button type="submit" className="btn btn-block btn-primary">Log in</button>
                    </form>
                    <div className="text-center my-2">
                      <span className="my-1">Don't have an account yet? <a href="/signup" className="custom-color">Sign Up</a></span>
                    </div>
                  </div>
                  </Spinner>
                </div>
               
              </div>
            </div>
          </div>
        </div>

        {/* Forgot password Modal */}

        <div className="modal fade login-background" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" className="close">&times;</span>
                </button>
              </div>
              <div className="modal-body">
              <Spinner display={this.props.isModalLoading}>
                <h2 className="text-center font-weight-bold">Recover Account</h2>
                <span className="d-flex text-center subtext">Enter your email address and an email with instructions will be sent to you.</span>

                <div className="js-form-message mb-3 mt-5">
                  <div className="js-focus-state input-group input-group form">
                    <div className="input-group-prepend form__prepend">
                      <span className={this.state.resetEmailError ? "input-group-text form__text error" : "input-group-text form__text"}>
                        <img src="/img/login-and-signup/email.png" className="phone" alt="" />
                      </span>
                    </div>
                    <input type="email" className={this.state.resetEmailError ? "form-control form__input error" : "form-control form__input"} name="resetPassEmail" value={this.state.resetPassEmail} placeholder="Email Address" aria-label="Enter your email address" onChange={this.handleChange} />
                  </div>
                  {
                    this.state.resetEmailError ? <div style={{ color: "red" }}>{this.state.resetEmailError}</div> : null
                  }
                </div>
                <button type="button" className="btn btn-block btn-primary mt-5"  onClick={this.handleResetPass}>Continue</button>
                <div className="text-center mt-3">
                  <span>Don't have an account yet? <a href="/signup" className="custom-color">Sign Up</a></span>
                </div>
                </Spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    err:state.TeamState,
    forgotPassStatus:state,
    isLoading:state.TeamState.isLoading,
    isModalLoading:state.TokenState.isLoading
  }
};

const mapDispatchToProps = {
  requestLogin,
  requestResetPassword,
  initialDefault
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))