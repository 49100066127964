import React, { Component } from 'react';
import { connect } from 'react-redux';
import { requestDeleteLeague } from '../../actions/createLeagueActionCreator'
import { initialDefault } from '../../actions/createTeamActionCreator'
class LeagueHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      league: [],
      leagueDetails:[]
    };
  }
  componentDidMount() {
    this.props.initialDefault()
    this.setState({
      league: [...this.state.league, this.props.LeagueData]
    })
  }

componentWillReceiveProps=(nextProps)=>{
    if(nextProps.LeagueStatus === 3){
      window.location.reload();
    }
}

handleLeagueId=(data)=>{
  this.setState({
    leagueDetails:data
  })
}
  handleDeleteLeague=(gameid)=>{
  let teamId =  window.location.pathname.split('/')[2];
  this.props.requestDeleteLeague(gameid,teamId)
  }
  
  render() {
    return (
      <div className="leaguehistory px-4 py-2">
        <div className="row">
        <table class="table table-striped">
  <thead>
    <tr>
      <th scope="col">Tournament Name</th>
      <th scope="col">Result</th>
      <th scope="col">Season</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
          {
            this.state.league[0] && this.state.league[0].map((data, index) => {
              return (
                <>
              <tr>
                <td>{data.name}</td>
                <td>{data.result}</td>
                <td>{data.season}, {data.year}</td>
                <td><img src="/img/create-team/delete.png" className="ml-auto mr-2" alt="delete" data-toggle="modal" data-target="#remove-modal" onClick={()=>this.handleLeagueId(data)}/>
                    <img src="/img/create-team/edit.png" alt="edit" data-toggle="modal" data-target="#leagueHistory" onClick={()=>this.props.GetLeagueData(data)}/>
                </td>
              </tr>
                </>
              )
            })
          }
  </tbody>
</table>
        </div>
        <div class="modal fade" id="remove-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{this.state.leagueDetails.name}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                Are you sure you want to delete this league?
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal" onClick={() => this.handleDeleteLeague(this.state.leagueDetails.id)}>Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    LeagueData: state.LeagueDataState.getLeagueData,
    LeagueStatus:state.LeagueDataState.status
  }
};

const mapDispatchToProps = {
  requestDeleteLeague,
  initialDefault
};

export default connect(mapStateToProps, mapDispatchToProps)(LeagueHistory)

