import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import axios from 'axios';
import { apiUrl } from '../../config';
import { FaInfoCircle, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import { Link } from "react-router-dom";
import Spinner from '../component/Spnner'
import Info from '../component/Info'
import Additional from '../component/Additional'
import Referee from '../component/Referee'
import AddAgeGroup from '../component/AddAgeGroup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import SimpleReactValidator from 'simple-react-validator';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/assets/index.css';
import { requestScrimmage, initialDefault } from '../../actions/scrimmageActionCreator'
import { requestUploadImage } from '../../actions/imageActionCreator'


function disabledHours() {
  const allHours = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
  return allHours;
}

let gender = "";
let add0, add1, add2, add3;
class ScrimmageRequest extends Component {
  constructor(props) {
    super(props);
    axios.defaults.baseURL = apiUrl;
    this.validator = new SimpleReactValidator();

    this.state = {
      uploadFile: "",
      isReferee: "",
      addField: false,
      desc: "",
      startDate: "",
      startTime: "",
      startTimeError: "",
      cutoffDate: "",
      duration: "",
      gameTime: "",
      locationName: "",
      address1: "",
      address2: null,
      city: "",
      state: "",
      country: null,
      zipCode: "",
      gender: "",
      strength: [],
      attachments: "",
      age: "",
      minSkill: null,
      maxSkill: null,
      addage: "",
      addminSkill: null,
      addmaxSkill: null,
      error: "",
      teamCount: null,
      refereeRequest: "no",
      priceRangeMin: "",
      priceRangeMax: "",
      priceRange: "",
      token: "",
      id: "",
      teamAgeGroup: ["6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18"],
      refereeError: ""
    };
  }

  componentDidMount = () => {

    gender = this.props.teamData.TeamState.teamDetailsData && this.props.teamData.TeamState.teamDetailsData.gender;
    this.setState({
      gender: gender
    })
    this.props.initialDefault();
    this.setState({
      token: localStorage.getItem("user_token"),
      id: this.props.match.params.id
    })
  }
  handleCreateScrimmage = () => {
    
    if (localStorage.getItem("user_id")!== null) {

    const { age, minSkill, maxSkill, startDate, startTime, teamCount, duration, locationName, address1, city, state, zipCode, cutoffDate } = this.state;
    axios.defaults.headers.common['Authorization'] = 'bearer ' + this.state.token
    let finalTime = (new Date(Date.parse(startDate))).toLocaleDateString()
    finalTime = new Date(finalTime + ' ' + startTime);
    let data = {
      name: "",
      description: this.state.desc,
      startDate: finalTime,
      duration: this.state.duration,
      cutoffDate: this.state.cutoffDate,
      attachments: this.state.attachments ? [{ name: this.state.uploadFile, url: this.state.attachments }] : [],
      location: {
        name: this.state.locationName,
        address1: this.state.address1,
        address2: null,
        city: this.state.city,
        state: this.state.state,
        country: null,
        zipCode: this.state.zipCode
      },
      matchCriteria: {
        gender: this.state.gender,
        strength: []
      },
      teamCount: this.state.teamCount,
      refereeRequest: this.state.refereeRequest,
      priceRange: this.state.priceRangeMin + "-" + this.state.priceRangeMax
    }
    var id = this.state.id
    this.state.addField ?
      (data.matchCriteria.strength.push({
        ageGroup: this.state.age,
        minSkill: this.state.minSkill,
        maxSkill: this.state.maxSkill
      },
        {
          ageGroup: this.state.addage,
          minSkill: this.state.addminSkill,
          maxSkill: this.state.addmaxSkill
        }
      ))
      :
      (data.matchCriteria.strength.push({
        ageGroup: this.state.age,
        minSkill: this.state.minSkill,
        maxSkill: this.state.maxSkill
      }))
    if (age && minSkill && maxSkill && startDate && startTime && teamCount && duration && locationName && address1 && city && state && zipCode && cutoffDate) {
      if (this.state.addField) {
        if (this.state.addage && this.state.addminSkill && this.state.addmaxSkill) {
          this.props.requestScrimmage(data, id)
        }
        else {
          this.setState({
            addageError: "This field is required",
            addminSkillError: "This field is required",
            addmaxSkillError: "This field is required"
          })
        }
      }
      else {
        this.props.requestScrimmage(data, id)
      }
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    }

  } else {
  
      this.props.history.push("/login")
  }
  }


  handleUpload = (event) => {
    this.setState({
      uploadFile: event.target.files[0] && event.target.files[0].name
    })
    if (event.target.files[0]) {
      this.props.requestUploadImage(event.target.files[0]);
    }
  }

  handleOptionChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value, })
  }

  AddAgeGroup = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value, })
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value })
  }

  handleDateChange = date => {
    localStorage.setItem("Date", date)
    this.setState({
      startDate: date
    }, () => {
      const today = new Date()
      today.setDate(today.getDate() + 1)
    })
  }

  handleTimeChange = (e) => {
    if (e !== null) {
      this.setState({
        startTime: (new Date(Date.parse(e._d))).toLocaleTimeString()
      })
    }
    else {
      this.setState({
        startTime: "",
      })
    }
  }

  handleDuration = (e) => {
    if (e !== null) {
      this.setState({
        duration: (new Date(Date.parse(e._d))).toLocaleTimeString()
      })
    }
    else {
      this.setState({
        duration: "",
      })
    }
  }

  handleCutoffDateChange = date => {
    localStorage.setItem("Date", date)
    this.setState({
      cutoffDate: date
    })
  }

  handleType = () => {
    var total = document.getElementById("char").value;
    document.getElementById("charcount").innerHTML = total.length + "/300";
  }

  handleRadioChange = (e) => {
    this.setState({
      refereeRequest: e.target.value,
    })
  }

  handleAddAgeGroup = (e) => {
    this.setState({
      addField: true
    })
  }

  removeAgeGroup = () => {
    this.setState({
      addField: false
    })
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.fileupload.ImageState.status === 3) {
      this.setState({
        attachments: nextProps.fileupload.ImageState.image && nextProps.fileupload.ImageState.image.toString()
      })
    }
    if (nextProps.ScrimmageCreateData && nextProps.ScrimmageCreateData.status === 3) {
      this.props.history.push("/teamdetails/" + this.state.id)
    }
  }


  render() {
    const today = new Date();
    today.setDate(today.getDate() + 1)
    let gender = this.props.teamData.TeamState.teamDetailsData && this.props.teamData.TeamState.teamDetailsData.gender
    let userTeamName = this.props.teamData.TeamState.teamDetailsData && this.props.teamData.TeamState.teamDetailsData.name
    return (
      <div className="space-2-bottom">
        <Spinner display={this.props.isLoading}>
          <div className="container-fluid">
            <div className="row justify-content-lg-between align-items-lg-start createScrimmmage">
              <div className="col pt-5">
                <h2 className="text-white">New scrimmage request</h2>
                <p className="text-white">This is your personal information, where you can edit at any time just click the edit button.</p>
              </div>
            </div>
          </div>
          <div className="container space-2-top">
            <div className="row pb-3">
            	<div className="col-8">
            	    <label className="h2">Request From:</label> <span className="font-weight-black h2">{userTeamName}</span>
                </div>
            	<div className="col-4 text-right">
                <Link to={"/teamdetails/" + window.location.pathname.split("/")[2]}>
                     Cancel &nbsp; <FaArrowRight />
                </Link>
                </div>
            </div>
            <div className="row align-items-lg-start mt-5">
              <div className="col-12 col-md-8">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="mb-7">
                      <div className="js-form-message mb-6 u-has-success">
                        <label className="d-block text-uppercase">
                          Opponent Team Level/Age Group
                        <span className="text-danger">*</span>
                        </label>
                        <select className={this.state.error ? "custom-select error" : "custom-select"} id={this.state.age === "" ? "select-color" : "teamAge"} name="age" value={this.state.age || ''} aria-invalid="false" onChange={this.handleOptionChange}>
                          <option value="">Select Team Level/Age Group</option>
                          <option disabled>_________</option>
                          <option disabled>SCHOOL TEAMS</option>
                          <option value="V" style={{ "color": "#000" }}>Varsity</option>
                          <option value="JV" style={{ "color": "#000" }}>Junior Varsity</option>
                          <option disabled>_________</option>
                          <option disabled>TRAVELLING TEAMS</option>
                          {
                            this.state.teamAgeGroup && this.state.teamAgeGroup.map((age, key) => {
                              return (
                                <option key={key} value={age || ''} style={{ "color": "#000" }}>{age} &amp; under</option>
                              )
                            })
                          }
                        </select>
                        {this.validator.message('', this.state.age, 'required')}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="mb-7">
                      <label className="d-block text-uppercase">
                        Opponent Team Skill Level
                    <span className="text-danger ">*</span>
                    &nbsp; <FaInfoCircle data-toggle="modal" data-target="#infomodal" style={{ "cursor": "pointer" }} />
                      </label>
                      <div className="row">
                        <div className="col-6 col-md-6">
                          <select className={this.state.error ? "custom-select text-center error" : "custom-select text-center"} id={this.state.minSkill === null || this.state.minSkill === "" ? "select-color" : "teamStrengthMin"} name="minSkill" value={this.state.minSkill || ''} aria-invalid="false" onChange={this.handleOptionChange}>
                            <option value="">Select Min Skill</option>
                            <option value="1" style={{ "color": "#000" }}>1</option>
                            <option value="2" style={{ "color": "#000" }}>2</option>
                            <option value="3" style={{ "color": "#000" }}>3</option>
                            <option value="4" style={{ "color": "#000" }}>4</option>
                            <option value="5" style={{ "color": "#000" }}>5</option>
                            <option value="6" style={{ "color": "#000" }}>6</option>
                          </select>
                          {this.validator.message('', this.state.minSkill, 'required')}
                        </div>
                        <div className="col-6 col-md-6">
                          <select className={this.state.error ? "custom-select error" : "custom-select"} id={this.state.maxSkill === null || this.state.maxSkill === "" ? "select-color" : "teamStrengthMax"} name="maxSkill" value={this.state.maxSkill || ''} aria-invalid="false" onChange={this.handleOptionChange}>
                            <option value="">Select Max Skill</option>
                            <option value="1" style={{ "color": "#000" }}>1</option>
                            <option value="2" style={{ "color": "#000" }}>2</option>
                            <option value="3" style={{ "color": "#000" }}>3</option>
                            <option value="4" style={{ "color": "#000" }}>4</option>
                            <option value="5" style={{ "color": "#000" }}>5</option>
                            <option value="6" style={{ "color": "#000" }}>6</option>
                          </select>
                          {this.validator.message('', this.state.maxSkill, 'required')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  this.state.addField ? <AddAgeGroup addage={this.state.addage} addminSkill={this.state.addminSkill} addmaxSkill={this.state.addmaxSkill} AddAgeGroup={this.AddAgeGroup} removeAgeGroup={this.removeAgeGroup} addageError={this.state.addageError} addminSkillError={this.state.addminSkillError} addmaxSkillError={this.state.addmaxSkillError} /> : <></>
                }
              </div>
              {
                this.state.addField ? null : (<div className="col-12 col-md-4">
                  <div className="mb-7 text-nowrap">
                    <label className="d-block text-uppercase float-right">
                      &nbsp;
                    </label><span className="d-none d-md-block"><br /></span>
                    <button type="button" className="btn btn-sm btn-primary text-white deleteleague text-wrap" onClick={this.handleAddAgeGroup}>Add Additional Age/Strength Level</button>
                    &nbsp;
                    <FaInfoCircle className="align-middle" data-toggle="modal" data-target="#additionalmodal" style={{ "cursor": "pointer" }} />
                  </div>
                </div>)
              }
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="mb-7">
                  <div className="js-form-message mb-6 u-has-success">
                    <label className="d-block text-uppercase">
                      Date
                    <span className="text-danger">*</span>
                    </label>
                    <div className={this.state.error ? "js-focus-state input-group form error" : "js-focus-state input-group form"}>
                      <DatePicker
                        placeholderText="Start Date"
                        selected={this.state.startDate}
                        onChange={this.handleDateChange}
                        minDate={new Date().setDate(new Date().getDate())}
                      />
                    </div>
                    {this.validator.message('', this.state.startDate, 'required')}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="mb-7">
                  <div className="js-form-message mb-6 u-has-success">
                    <label className="d-block text-uppercase">
                      Game Time
                        <span className="text-danger">*</span>
                    </label>
                    <div className={this.state.error ? "js-focus-state input-group form error" : "js-focus-state input-group form"}>
                      <TimePicker placeholder="Select Time" use12Hours showSecond={false} minuteStep={15} defaultOpenValue={moment("12:00 PM", ["h:mm A"])} onChange={this.handleTimeChange} />
                    </div>
                    {this.validator.message('', this.state.startTime, 'required')}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="mb-7">
                  <div className="js-form-message mb-6 u-has-success">
                    <label className="d-block text-uppercase">
                      Number of teams Desired
                        <span className="text-danger">*</span>
                    </label>
                    <input className={this.state.error ? "form-control form__input error" : "form-control form__input"} type="number" min="1" name="teamCount" value={this.state.teamCount || ''} required="" placeholder="1" onChange={this.handleChange} />
                    {this.validator.message('', this.state.teamCount, 'required')}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="mb-7">
                  <div className="js-form-message mb-6 u-has-success">
                    <label className="d-block text-uppercase">
                      Game Duration
                        <span className="text-danger">*</span>
                    </label>
                    <div className={this.state.error ? "js-focus-state input-group form error" : "js-focus-state input-group form"}>
                      <TimePicker placeholder="Set Duration" disabledHours={disabledHours} hideDisabledOptions="true" showSecond={false} minuteStep={30} openTo="hours" defaultOpenValue={moment("1:00", ["h:mm"])} onChange={this.handleDuration} />
                    </div>
                    {this.validator.message('', this.state.duration, 'required')}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="mb-7">
                  <div className="js-form-message mb-6 u-has-success">
                    <label className="d-block text-uppercase">
                      Team Gender
                  </label>
                    <div className="js-focus-state input-group form">
                      <input className="form-control form__input" type="text" name="" required="" value={gender && gender === "male" ? "Male" : gender === "female" ? "Female" : gender === "co-ed" ? "Co-ed" : null} disabled />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="mb-7">
                  <div className="js-form-message mb-6 u-has-success">
                    <label className="d-block text-uppercase">
                      Venue Name
                        <span className="text-danger">*</span>
                    </label>
                    <div className={this.state.error ? "js-focus-state input-group form error" : "js-focus-state input-group form"}>
                      <input className="form-control form__input" type="text" name="locationName" required="" value={this.state.locationName || ''} placeholder="Atlas Stadium" onChange={this.handleChange} />
                    </div>
                    {this.validator.message('', this.state.locationName, 'required')}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="mb-7">
                  <div className="js-form-message mb-6 u-has-success">
                    <label className="d-block text-uppercase">
                      Venue Address
                        <span className="text-danger">*</span>
                    </label>
                    <div className={this.state.error ? "js-focus-state input-group form error" : "js-focus-state input-group form"}>
                      <GooglePlacesAutocomplete
                        onSelect={({ terms }) => (
                          add0 = terms[0] && terms[0].value ? terms[0] && terms[0].value : '',
                          add1 = terms[1] && terms[1].value ? terms[1] && terms[1].value : '',
                          this.setState({ address1: add0 + " " + add1, city: terms[2] && terms[2].value, state: terms[3] && terms[3].value })
                        )}
                      />
                    </div>
                    {this.validator.message('', this.state.address1, 'required')}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="mb-7">
                  <div className="js-form-message mb-6 u-has-success">
                    <label className="d-block text-uppercase">
                      Venue City
                        <span className="text-danger">*</span>
                    </label>

                    <div className={this.state.error ? "js-focus-state input-group form error" : "js-focus-state input-group form"}>
                      <input className="form-control form__input" type="text" name="city" value={this.state.city || ''} required="" placeholder="City" onChange={this.handleChange} />
                    </div>
                    {this.validator.message('', this.state.city, 'required')}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="mb-7">
                  <div className="js-form-message mb-6 u-has-success">
                    <label className="d-block text-uppercase">
                      Venue State
                        <span className="text-danger">*</span>
                    </label>

                    <div className={this.state.error ? "js-focus-state input-group form error" : "js-focus-state input-group form"}>
                      <input className="form-control form__input" type="text" name="state" value={this.state.state || ''} required="" placeholder="State" onChange={this.handleChange} />
                    </div>
                    {this.validator.message('', this.state.state, 'required')}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="mb-7">
                  <div className="js-form-message mb-6 u-has-success">
                    <label className="d-block text-uppercase">
                      Zip Code
                        <span className="text-danger">*</span>
                    </label>

                    <div className={this.state.error ? "js-focus-state input-group form error" : "js-focus-state input-group form"}>
                      <input className="form-control form__input zip" type="number" name="zipCode" value={this.state.zipCode || ''} required="" placeholder="Zip Code" onChange={this.handleChange} />
                    </div>
                    {this.validator.message('', this.state.zipCode, 'required')}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="mb-7">
                  <div className="js-form-message mb-6 u-has-success">
                    <label className="d-block text-uppercase">
                      Respond By
                        <span className="text-danger">*</span>
                    </label>
                    <div className={this.state.error ? "js-focus-state input-group form error" : "js-focus-state input-group form"}>
                      <DatePicker
                        placeholderText="Response Date"
                        selected={this.state.cutoffDate}
                        onChange={this.handleCutoffDateChange}
                        minDate={new Date()}
                        maxDate={this.state.startDate !== "" ? new Date(Date.parse(this.state.startDate)).toLocaleDateString() === new Date(Date.parse(today)).toLocaleDateString() ? today : new Date(this.state.startDate) : ""}
                      />
                    </div>
                    {this.validator.message('', this.state.cutoffDate, 'required')}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="mb-7">
                  <div className="js-form-message mb-6 u-has-success">
                    <label className="d-block text-uppercase">
                      Special Instructions
                  </label>
                    <div className="js-focus-state input-group form">
                      <textarea maxLength="300" className="form-control form__input" rows="6" value={this.state.desc || ''} name="desc" required="" placeholder="Please type your instruction here" aria-label="How can we help you?" onKeyUp={this.handleType} id="char" onChange={this.handleChange}></textarea>
                    </div>
                    <span className="font-size-13 text-lh-sm">Maximum 300 characters</span>
                    <span className="text-muted float-right" id="charcount"></span>
                    <div id="email-error" className="invalid-feedback" style={{ display: "none" }}></div></div>
                </div>
              </div>
              <div className="col-md-2 col-12 d-none d-md-block">
                <div className="mb-7">

                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="mb-7">
                  <div className="row">
                    <div className="col-12">
                      <label className="d-block text-uppercase">
                        Scrimmage will have a referee/umpire
                    &nbsp;<FaInfoCircle data-toggle="modal" data-target="#refereemodal" style={{ "cursor": "pointer" }} />
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="custom-control custom-radio custom-control-inline form-selector-modern">
                        <input type="radio" id="referee1" className="custom-control-input form-selector-modern__input" name="productShippingSelector" value="no" onClick={this.handleRadioChange} />
                        <label className={`custom-control-label form-selector-modern__label ${this.state.refereeRequest === 'no' ? 'check' : ''}`} htmlFor="referee1">
                          <span className="d-block h6 mb-1">No</span>
                        </label>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="custom-control custom-radio custom-control-inline form-selector-modern">
                        <input type="radio" id="referee" className="custom-control-input form-selector-modern__input" name="productShippingSelector" value="yes" onClick={this.handleRadioChange} />
                        <label className="custom-control-label form-selector-modern__label" htmlFor="referee">
                          <span className="d-block h6 mb-1">Yes</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label className="d-block text-uppercase">
                        Cost range per team
                    </label>
                    </div>
                    <div className="col-1 text-right py-2 pr-0">$</div>
                    <div className="col-5 pl-1">
                      <input className="form-control form__input d-inline-block" type="number" min="0" name="priceRangeMin" value={this.state.priceRangeMin || ''} required="" placeholder="5" onChange={this.handleChange} />
                    </div>
                    <div className="col-1 text-right py-2 pr-0">$</div>
                    <div className="col-5 pl-1">
                      <input className="form-control form__input" type="number" min="0" name="priceRangeMax" value={this.state.priceRangeMax || ''} required="" placeholder="10" onChange={this.handleChange} />
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-12 mb-2">
                <div className="d-flex justify-content-sm-between align-items-sm-center">
                  <Link to={"/teamdetails/" + window.location.pathname.split("/")[2]}>
                    <FaArrowLeft /> &nbsp; Cancel
                  </Link>

                  <div className="ml-auto my-auto">
                    {
                      this.state.error ? <div style={{ color: "red" }}>{this.state.error}</div> : null
                    }
                  </div>
                  <div className="ml-auto my-auto">

                    <button type="button" className="btn btn-primary" onClick={this.handleCreateScrimmage}>Send</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </Spinner>
        <div className="modal fade" id="infomodal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <section className="modal-body px-3 my-3">
                  <Info />
                </section>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="additionalmodal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <section className="modal-body px-3 my-3">
                  <Additional />
                </section>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="refereemodal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <section className="modal-body px-3 my-3">
                  <Referee />
                </section>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fileupload: state,
    teamData: state,
    ScrimmageCreateData: state.ScrimmageState,
    isLoading: state.ScrimmageState.isLoading
  }
};

const mapDispatchToProps = {
  requestScrimmage,
  requestUploadImage,
  initialDefault
};
export default connect(mapStateToProps, mapDispatchToProps)(ScrimmageRequest)
