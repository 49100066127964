import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { apiUrl } from '../../config';
import axios from "axios";


class Addteam extends Component {
    constructor(props) {
        super(props);
        axios.defaults.baseURL = apiUrl;
        this.state = {
        userName:""
        };
    }

    componentDidMount=()=>{
        this.setState({
            userName:localStorage.getItem("user_name")
        })
    }

    render() {
        return (
            <div>
                <div className="bg-img-hero create-team-background">
                    <div className="d-sm-flex align-items-sm-center height-100vh--sm">
                        <div className="container space-1-top space-3-top--lg space-2-bottom space-3-bottom--lg">
                            <div className="row">
                                <div className="offset-md-6 col-md-6">
                                    <div className="mx-lg-auto">
                                        <div>
                                            <h4 className="font-weight-normal\ mb-5 text-white">Welcome to GameFinder365! Through our platform, we are empowering youth sports teams to control their own schedule. Here is where your journey begins to easily connect with quality, well-matched competition in your local area with the click of a button. Add a team, and create your first scrimmage request to get started!</h4>
                                            <Link to="/createteam">
                                                <div className="btn btn-sm btn-primary text-white addteambtntext mb-5">
                                                Add your first team
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Addteam;