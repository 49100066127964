import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { apiUrl } from '../../config';
import axios from "axios";


class CreateNewScrimmage extends Component {
    constructor(props) {
        super(props);
        axios.defaults.baseURL = apiUrl;
        this.state = {

        };
    }

    render() {
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <div className="bg-img-hero create-scrimmage-background">
                                <div className="d-sm-flex align-items-sm-center">
                                    <div className="pt-5">
                                        <div className="row">

                                            <div className="col-md-7">
                                                <div className="mx-lg-auto">
                                                    <div>
                                                        <img src="/img/create-scrimmage/create-scrimmage-bg.png" className="img-fluid" alt="scrimmage-img" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-5 my-auto">
                                                <div className="mx-lg-auto">
                                                    <div>
                                                        <h1 className="custom-h1 font-weight-medium">New scrimmage request</h1>
                                                        <p className="custom-p subheader mb-5">Lorem ipsum dolor sit amet, consetere no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
                                                        <p className="lead custom-p font-weight-normal mb-5">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                                                        <Link to="/scrimmagerequest">
                                                            <div className="btn btn-sm btn-primary text-white">
                                                                Create Scrimmage
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateNewScrimmage;