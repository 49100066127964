import React, { Component } from 'react';

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="container">
          <div className="row justify-content-lg-between align-items-lg-center my-5">
            <div className="col-lg-12">
              <p className="h2 text-center font-italic quote">" All he wanted for his team was a few competitive games to prepare them for larger tournaments. There had to be a better way - and that's when the idea of GameFinder365 was born!"</p>
            </div>
          </div>
        </div>
        <div className="space-2-bottom space-2--lg mt-lg-9">
          <div className="position-relative space-1--lg">
            <div className="container">
              <div className="col-lg-5 position-absolute-top-left-0--lg bg-img-hero the-origin"></div>
              <div className="row justify-content-lg-end align-self-lg-center">
                <div className="col-lg-6 mt-5 mt-lg-0">
                  <h2 className="h2 font-weight-black">The Origins</h2>
                  <p>Kenny Wood, a former collegiate and professional basketball player, loved everything about coaching his daughters travel basketball team. That is, everything but the difficulty he had organizing game-like experiences to prepare the girls for their season.</p>
                  <p>The GameFinder365 concept hit him after the girls competed in a 3-game local tournament an hour away from their hometown. The tournament was supposed to give the team a chance to compete against other good players instead of each other... What did they get instead??</p>
                  <table className="table"><tbody>
                      <tr>
                          <td><span class="font-weight-bold">The Competition</span> <i class="fas fa-long-arrow-alt-right"></i> Poorly matched</td>
                      </tr><tr>    
                          <td><span class="font-weight-bold">The Event Organization</span> <i class="fas fa-long-arrow-alt-right"></i> Abysmal</td>
                      </tr><tr>    
                          <td><span class="font-weight-bold">Travel Costs</span> <i class="fas fa-long-arrow-alt-right"></i> Prohibitive</td>
                      </tr><tr>    
                          <td><span class="font-weight-bold">Wasted Time</span> <i class="fas fa-long-arrow-alt-right"></i> Too Much!</td>
                      </tr>
                  </tbody></table> 
                  <br/>
                  <p><a href="/why" className="btn btn-lg btn-primary"><h4 className="font-weight-black m-0">Why Gamefinder365?</h4></a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AboutUs;