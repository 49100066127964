import url from '../helpers/environment';
const axios = require('axios');
axios.defaults.baseURL = url


export const requestUploadImage = (file) => async dispatch => {
    const header = { "Content-Type": "multipart/form-data" }
    const formData = new FormData();
    formData.append('file', file);
    axios.post('upload-file', formData, { headers: header }).then(response => {
        dispatch({ type: 'RECIVED_UPLOAD_SUCCESS', image: response.data });
    }).catch(error => {
        console.log(error)
        dispatch({ type: 'RECIVED_UPLOAD_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_UPLOAD' });
}


