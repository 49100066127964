import React, { Component } from 'react';
import ReactPlayer from "react-player/vimeo";
import { connect } from 'react-redux';
import { apiUrl } from '../config';
import axios from "axios";
import { toast } from "react-toastify";

toast.configure();

class Home extends Component {
  constructor(props) {
    super(props);
    axios.defaults.baseURL = apiUrl;
    this.state = {
      email: "",
      password: "",
      class: "u-video-player",
      emailError: "",
      passwordError: "",
      resetEmail: "",
      resetEmailError: "",
      COVID1: true
    };
  }

  playVideo = () => {
    this.setState({
      class: "u-video-player u-video-player__played"
    })
  }

  render() {
    return (
  
  
  <div>

     <div className="modal fade" id="subscribe" tabIndex="-1" role="dialog" aria-labelledby="subscribe" aria-hidden="false">
      <div className="modal-dialog modal-dialog-centered modal-dialog-view-history" role="document">
	<div className="modal-content">
	  <div className="modal-body text-center">
	    <button type="button" className="close float-right" data-dismiss="modal" aria-label="Close">
	      <span aria-hidden="true">&times;</span>
	    </button>
	    <h2 className="font-weight-black">Join Our Mailing List</h2>
	    <div>Enter your email address below to receive timely updates on youth sports, tips from coaches, and new GameFinder365 features.</div>
	    <p className="font-size-13 text-lh-sm">You can unsubscribe at any time.</p>
	  </div>
	</div>
      </div>
     </div>

     <div className="gradient-overlay-half-dark-v3 home-background space-1-top space-1-bottom">
       <div className="container">
	 <div className="row justify-content-lg-between align-items-lg-center">
	   <div className="col-md-8 mx-auto text-center">
	     <h3 className="text-white font-weight-black">"Your&nbsp;next&nbsp;practice could&nbsp;be&nbsp;your <span className="custom-color">best</span>&nbsp;game."</h3>
	     <div className="intro-video bg-white rounded p-2 mt-3">
	       <div id="youTubeVideoPlayer" className={this.state.class}>
		 <div className="embed-responsive embed-responsive-16by9">		   
		   <ReactPlayer
  		      url='//player.vimeo.com/video/493859446'
		      className='embed-responsive-item'
			config={{
			  vimeo: {
			   playerOptions: { controls: true,  }
			  }
			}}
  		      width='100%'
          	      height='100%'
                    />
		 </div>
	       </div>
	     </div>
	     <h2 className="my-3 text-white">Your Team. Your Schedule. Your&nbsp;Control.</h2>
	     {
	     localStorage.getItem("user_id")!== null ? 
	     	<a href={this.props.teamLength === 0 ?"/addteam":"/yourteam"} className="btn btn-lg btn-white bg-white py-2 px-5 mb-5 btn-join">JOIN US TODAY!<br/><span className="gamefinder font-weight-black">GameFinder</span><span className="custom-color font-weight-black">365</span></a>
	     	:
	     	<a href="/signup" className="btn btn-lg btn-white bg-white py-2 px-5 mb-5 btn-join">JOIN US TODAY!<br/><span className="gamefinder font-weight-black">GameFinder</span><span className="custom-color font-weight-black">365</span></a>
	     }
	   </div>
	 </div>
       </div>
     </div>

     <div className="container space-1-top">
       <div className="w-md-90 mx-md-auto">
         <div className="px-3 text-center">
           <p className="mb-5"><span className="font-weight-black"><span className="gamefinder">GameFinder</span><span className="custom-color">365</span></span> (GF 365) is an online platform that enables teams to organize scrimmages and games against similarly skilled teams within a specific geographic area, which allows teams to play more games while saving time and money.</p>
         </div>
       </div>
     </div>

  </div>


    );
  }
}

const mapStateToProps = (state) => {
  return {
    success:state.TokenState.status,
  }
};

export default connect(mapStateToProps)(Home)