import React, { Component } from 'react';

class Additional extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }
  render() {
    return (
      <>
      <h5>Additional Age/Skill Levels</h5>
      <p className="font-size-14 text-lh-sm">To increase the probability of finding matches, consider selecting a younger age group at a higher skill level or an older age group at a lower skill level.</p>
      </>
    );
  }
}

export default Additional;