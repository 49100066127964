const defaultCreateTeamState = {
    createTeam: null,
    isLoading: false,
    status: 0,
    forgotPassStatus:null
};


export default function (state = defaultCreateTeamState, action) {

    switch (action.type) {

        case 'DEFAULT_SCRIMMAGE':
            return { ...state, isLoading: false, status: 0, forgotPassStatus:null};

        case 'REQUESTED_CREATE_TEAM':
            return { ...state, isLoading: true, status: 1 };
        case 'RECIVED_CREATE_TEAM_FAILURE':
            return { ...state, isLoading: false, status: 2 };
        case 'RECIVED_CREATE_TEAM_SUCCESS':
            return { ...state, createTeam: action.createTeamData, isLoading: false, status: 3 };
            
            case 'RECIVED_GET_TEAM_EDIT_DATA':
                return { ...state, isLoading: true };
            case 'RECIVED_GET_TEAM_EDIT_DATA_FAILURE':
                return { ...state, isLoading: false };
            case 'RECIVED_GET_TEAM_EDIT_DATA_SUCCESS':
                return { ...state, getTeamEditData: action.getTeamEditData, isLoading: false };

                case 'REQUESTED_TEAM_EDIT':
                    return { ...state, isLoading: true, status: 1};
                case 'RECIVED_TEAM_EDIT_FAILURE':
                    return { ...state, isLoading: false, status: 2 };
                case 'RECIVED_TEAM_EDIT_SUCCESS':
                    return { ...state, TeamEdit: action.TeamEdit, isLoading: false, status: 3 };
        default:
    }

    return state
}