import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import Spinner from '../component/Spnner'
import { toast } from "react-toastify";
import { Link, withRouter } from 'react-router-dom';
import { FaUserAlt, FaPowerOff, FaUserFriends, FaDollarSign } from 'react-icons/fa';
import { emailValidate } from "../../Util"
import { requestProfileData, requestVerifyProfileData } from '../../actions/profileActionCreator';
import { initialDefault } from '../../actions/profileActionCreator'
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: '',
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      password: "",
      resetFirstNameError: "",
      resetLastNameError: "",
      resetPhoneError: "",
      resetEmailError: "",
      resetPasswordError:"",
      userName: "",
      token: "",
      currentUrl: ''
    };
  }

  componentDidMount = () => {
    this.setState({
      firstName: this.props.profileState.profileData && this.props.profileState.profileData.firstName,
      lastName: this.props.profileState.profileData && this.props.profileState.profileData.lastName,
      phone: this.props.profileState.profileData && this.props.profileState.profileData.phone,
      email: this.props.profileState.profileData && this.props.profileState.profileData.email,
    })
    this.setState({
      token: localStorage.getItem('user_token'),
      userName: localStorage.getItem('user_name'),
      resetEmailError:"",
      resetPasswordError:""
    }, () => {
      if(localStorage.getItem('user_token')){
      this.getProfileData();
    }
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ currentUrl: this.props.location.pathname, userName: localStorage.getItem('user_name') })
    if(nextProps.profileState.errorStatus ===401){
      this.props.history.push("/login")
    }
    if(nextProps.profileState.status === 2){
      switch(nextProps.profileState.profilePassErr){
        case "Email is already in use":
          this.setState({
            resetEmailError:nextProps.profileState.profilePassErr,
            email: this.state.email,
            firstName: nextProps.profileState.profileData && nextProps.profileState.profileData.firstName,
            lastName: nextProps.profileState.profileData && nextProps.profileState.profileData.lastName,
            phone: nextProps.profileState.profileData && nextProps.profileState.profileData.phone,
          })
          break;
          case "Password is Incorrect":
            this.setState({
              resetPasswordError:nextProps.profileState.profilePassErr,
              resetEmailError:""
            })
            break;
            default:
              this.setState({
                resetEmailError:""
              })
      }
    }
  }

  handleProfileUpdateModal=()=>{
    this.setState({
      resetEmailError:"",
      resetPasswordError:"",
      firstName: this.props.profileState.profileData && this.props.profileState.profileData.firstName,
      lastName: this.props.profileState.profileData && this.props.profileState.profileData.lastName,
      phone: this.props.profileState.profileData && this.props.profileState.profileData.phone,
      email: this.props.profileState.profileData && this.props.profileState.profileData.email,
      password:""
    })
  }

  getProfileData = () => {
    axios.defaults.headers.common['Authorization'] = 'bearer ' + localStorage.getItem('user_token')
    let userId = localStorage.getItem("user_id")
    this.props.requestProfileData(userId)
  }

  validatePassword = (e) => {
    axios.defaults.headers.common['Authorization'] = 'bearer ' + localStorage.getItem('user_token')
    if (this.state.email) {
      const isValidEmail = emailValidate(this.state.email);
      if (isValidEmail) {
        this.setState({ resetEmailError: "" });
        const data = {
          email: this.state.email,
          password: this.state.password
        }
        if(data.password){
          this.setState({
            resetPasswordError:""
          })
          const updateData = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phone: this.state.phone
          }
          let userId = localStorage.getItem("user_id")
          this.props.requestVerifyProfileData(data, updateData, userId)
        }
        else{
          this.setState({
            resetPasswordError:"This field is required"
          })
        }
      }
      else{
        this.setState({
          resetEmailError: "Not a valid format"
        })
      }
  }
  else {
    this.setState({
      resetEmailError: "This field is required"
    })
  }}


  handleLogout = () => {
    window.localStorage.clear();
    sessionStorage.clear();
    this.props.history.push("/")
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value })
  }


  render() {
    return (

      <div>
        <header id="header" className="u-header u-header--modern u-header--bordered">
          <div className="u-header__section">
            <div id="logoAndNav" className="container-fluid">
              <nav className="js-mega-menu navbar navbar-expand-lg u-header__navbar hs-menu-initialized hs-menu-horizontal">
                <a className="navbar-brand u-header__navbar-brand" href="/" aria-label="Space">
                  <img className="u-header__navbar-brand-default" src="/img/login-and-signup/logo.png" alt="Logo" />
                  <img className="u-header__navbar-brand-mobile" src="/img/login-and-signup/logo.png" alt="Logo" />
                </a>

                <button type="button" className="navbar-toggler btn u-hamburger u-header__hamburger collapsed" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navBar" data-toggle="collapse" data-target="#navBar">
                  <span id="hamburgerTrigger" className="u-hamburger__box">
                    <span className="u-hamburger__inner"></span>
                  </span>
                </button>

                <div id="navBar" className="navbar-collapse u-header__navbar-collapse py-0 collapse">
                <ul className="navbar-nav u-header__navbar-nav">
                    <li className="nav-item u-header__nav-item-btn mr-5">
                    <Link className="u-header__nav-link navtext" to={this.props.teamLength === 0 ?"/addteam":"/yourteam"} aria-haspopup="true" aria-expanded="false" aria-labelledby="docsSubMenu">
                      <FaUserFriends />  &nbsp; My Teams
                    </Link>
                    </li>
                    <li className="nav-item u-header__nav-item-btn dropdown">
                      <a href="#!" className="btn btn-sm btn-outline-primary deleteleague text-white dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                      <FaUserAlt /> &nbsp;
                        Hello <span className="text-capitalize">{this.state.userName}</span>
                      </a>
                      <div className="dropdown-menu py-0" aria-labelledby="dropdownMenuButton">
                        {/* <Link className="dropdown-item mb-0" to={this.props.teamLength === 0 ?"/addteam":"/yourteam"}><FaUserFriends />  &nbsp; My Teams</Link>
                        <hr className="m-0" /> */}
                        <Link className="dropdown-item mb-0" to={{pathname:"/pricing",state:{ prevUrl: this.state.currentUrl }}}><FaDollarSign />  &nbsp; Billing</Link>
                        <hr className="m-0" />
                        <a className="dropdown-item" href="#!" data-toggle="modal" data-target="#exampleModalCenter" onClick={this.handleProfileUpdateModal}><FaUserAlt />  &nbsp; Profile</a>
                        <hr className="m-0" />
                        <a className="dropdown-item mb-0" href="#!" onClick={this.handleLogout}><FaPowerOff />  &nbsp; Logout</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </header>

	{
	localStorage.getItem("user_id")!== null ? 

        <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
            <Spinner display={this.props.isLoading}>
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" className="close">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h2 className="text-center font-weight-bold">Profile Update</h2>
                <span className="d-flex text-center subtext">Update your profile info below, password is required to make changes.</span>
                <div className="js-form-message mb-3 mt-5">
                  <div className="js-focus-state input-group input-group form">
                    <div className="input-group-prepend form__prepend">
                      <span className={this.state.resetFirstNameError ? "input-group-text form__text error" : "input-group-text form__text"}>
                        <img src="/img/login-and-signup/info.png" className="phone" alt="first name" />
                      </span>
                    </div>
                    <input type="text" className={this.state.resetFirstNameError ? "form-control form__input error" : "form-control form__input"} name="firstName" value={this.state.firstName || ''} placeholder="First Name" aria-label="Enter your first name" onChange={this.handleChange} />
                  </div>
                  {
                    this.state.resetFirstNameError ? <div style={{ color: "red" }}>{this.state.resetFirstNameError}</div> : null
                  }
                </div>

                <div className="js-form-message mb-3 mt-5">
                  <div className="js-focus-state input-group input-group form">
                    <div className="input-group-prepend form__prepend">
                      <span className={this.state.resetLastNameError ? "input-group-text form__text error" : "input-group-text form__text"}>
                        <img src="/img/login-and-signup/info.png" className="phone" alt="help" />
                      </span>
                    </div>
                    <input type="text" className={this.state.resetLastNameError ? "form-control form__input error" : "form-control form__input"} name="lastName" value={this.state.lastName || ''} placeholder="Last Name" aria-label="Enter your last name" onChange={this.handleChange} />
                  </div>
                  {
                    this.state.resetLastNameError ? <div style={{ color: "red" }}>{this.state.resetLastNameError}</div> : null
                  }
                </div>

                <div className="js-form-message mb-3 mt-5">
                  <div className="js-focus-state input-group input-group form">
                    <div className="input-group-prepend form__prepend">
                      <span className={this.state.resetPhoneError ? "input-group-text form__text error" : "input-group-text form__text"}>
                        <img src="/img/login-and-signup/phone.png" className="phone" alt="last name" />
                      </span>
                    </div>
                    <input type="number" className={this.state.resetPhoneError ? "form-control form__input error" : "form-control form__input"} name="phone" min="0" value={this.state.phone || ''} placeholder="Phone" aria-label="Enter your phone" onChange={this.handleChange} />
                  </div>
                  {
                    this.state.resetPhoneError ? <div style={{ color: "red" }}>{this.state.resetPhoneError}</div> : null
                  }
                </div>

                <div className="js-form-message mb-3 mt-5">
                  <div className="js-focus-state input-group input-group form">
                    <div className="input-group-prepend form__prepend">
                      <span className={this.state.resetEmailError ? "input-group-text form__text error" : "input-group-text form__text"}>
                        <img src="/img/login-and-signup/email.png" className="phone" alt="email" />
                      </span>
                    </div>
                    <input type="email" className={this.state.resetEmailError ? "form-control form__input error" : "form-control form__input"} name="email" value={this.state.email || ''} placeholder="Email" aria-label="Enter your email" onChange={this.handleChange} />
                  </div>
                  {
                    this.state.resetEmailError ? <div style={{ color: "red" }}>{this.state.resetEmailError}</div> : null
                  }
                </div>

                <div className="js-form-message mb-3 mt-5">
                  <div className="js-focus-state input-group input-group form">
                    <div className="input-group-prepend form__prepend">
                      <span className={this.state.resetPasswordError ? "input-group-text form__text error" : "input-group-text form__text"}>
                        <img src="/img/login-and-signup/pass.png" className="phone" alt="password" />
                      </span>
                    </div>
                    <input type="password" className={this.state.resetPasswordError ? "form-control form__input error" : "form-control form__input"} name="password" value={this.state.password || ''} placeholder="Password" aria-label="Password" onChange={this.handleChange} />
                  </div>
                  {
                    this.state.resetPasswordError ? <div style={{ color: "red" }}>{this.state.resetPasswordError}</div> : null
                  }
                </div>

                <button type="submit" className="btn btn-block btn-primary mt-5 font-weight-bold" aria-label="Close" onClick={this.validatePassword}>Save</button>
              </div>
              </Spinner>
            </div>
          </div>
        </div>
        
        :

	this.props.history.push("/login")

	}

      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    accessToken: state.TokenState,
    profileState: state.ProfileState,
    isLoading:state.ProfileState.isLoading,
    teamLength:state.TeamState.teamData && state.TeamState.teamData.length,
    initial:state
  }
};

const mapDispatchToProps = {
  requestProfileData,
  requestVerifyProfileData,
  initialDefault
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navigation))