import url from '../helpers/environment';
const axios = require('axios');
axios.defaults.baseURL = url


export const requestProfileData = (userId) => async dispatch => {
        axios.get('user/'+userId).then(response => {
        var profileData = response.data;
        dispatch({ type: 'RECIVED_PROFILE_DATA_SUCCESS', profileData });
    }).catch(error => {
        dispatch({ type: 'RECIVED_PROFILE_DATA_FAILURE', errorStatus:error.response && error.response.status });
    });
    dispatch({ type: 'REQUESTED_PROFILE_DATA' });
}

export const requestVerifyProfileData = (varify,updateData,userId) => async dispatch => {
    axios.post('verify-password/',varify).then(response => {
        dispatch({ type: 'RECIVED_PROFILE_PASSWORD_VARIFY_SUCCESS' });
       dispatch(requestUpdatedProfileData(updateData,userId))
    }).catch(error => {
        console.log(error)
        const profilePassErr = error.response.data
        dispatch({ type: 'RECIVED_PROFILE_PASSWORD_VARIFY_FAILURE', profilePassErr:profilePassErr });
    });
    dispatch({ type: 'REQUESTED_PROFILE_PASSWORD_VARIFY' });
}

export const requestUpdatedProfileData = (data,userId) => async dispatch => {
    axios.put('user',data).then(response => {
        localStorage.setItem('user_name', response.data && response.data.firstName)
        dispatch({ type: 'RECIVED_PROFILE_DATA_UPDATED_SUCCESS' });
        dispatch(requestProfileData(userId))
        window.location.reload();
    }).catch(error => {
        console.log(error)
        dispatch({ type: 'RECIVED_PROFILE_DATA_UPDATED_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_PROFILE_DATA_UPDATED' });
}

export const initialDefault = () => async dispatch => {
    dispatch({ type: 'DEFAULT_SCRIMMAGE' });
}