import React, { Component } from 'react';

class Referee extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }
  render() {
    return (
      <>
      <h5>Referee/Umpire Agreement</h5>
      <p className="font-size-14 text-lh-sm">Host team is scheduling umpires/referees for the game. When outside referees/officials are hired, participating teams will share the cost. By accepting the scrimmage/game request, you are agreeing to pay your portion of the officiant fees.</p>
      </>
    );
  }
}

export default Referee;