import React, { Component } from 'react';

class CreateLeagueHistory extends Component {
    constructor(props) {
        super(props);
        this.state = { 
         };
    }
    render() {
        return (
            <div>
              <hr />
             <div className="row">
                        <div className="w-100"></div>
                        <div className="col-md-4">
                          <div className="js-form-message mb-6 u-has-success">
                            <label className="h6 small d-block text-uppercase">
                              League/Tournament Name
                            <span className="text-danger">*</span>
                            </label>

                            <div className="js-focus-state input-group form">
                              <input className="form-control form__input" name="leagueName" type="text" required="" placeholder="Champions Trophy" value={this.props.leagueName} onChange={this.props.leagueDataChange} />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="js-form-message mb-6 u-has-success">
                            <label className="h6 small d-block text-uppercase">
                              League/Tournament Year
                            <span className="text-danger">*</span>
                            </label>
                            <select className="custom-select" aria-invalid="false" name="leagueYear" value={this.props.leagueYear} onChange={this.props.leagueYearChange}>
                              <option value="">Select Tournament Year</option>
                              <option value="2019">2019</option>
                              <option value="2018">2018</option>
                              <option value="2017">2017</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="js-form-message mb-6 u-has-success">
                            <label className="h6 small d-block text-uppercase">
                              Season
                            <span className="text-danger">*</span>
                            </label>

                            <div className="js-focus-state input-group form">
                              <input className="form-control form__input" name="season" type="text" required="" placeholder="Summer" value={this.props.season} onChange={this.props.leagueDataChange} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                      <div className="col-md-6">
                      <div className="mb-7">
                <div className="js-form-message mb-6 u-has-success">
                  <label className="h6 small d-block text-uppercase">
                  Additional Details
                  </label>

                  <div className="js-focus-state input-group form">
                    <textarea maxlength="300" className="form-control form__input" rows="6" name="additionalDesc" value={this.props.additionalDesc} required="" onKeyUp={this.handleType} id="charlimit" onChange={this.props.leagueDataChange}></textarea>
                  </div>
                  <span>Maximum 300 character.</span>
                  <span className="text-muted float-right" id="charcount1"></span>
                  <div id="email-error" className="invalid-feedback" style={{ display: "none" }}></div></div>
              </div>
                      </div>
                      <div className="col-md-6">
                      <div className="js-form-message mb-6 u-has-success">
                            <label className="h6 small d-block text-uppercase">
                            Result
                              <span className="text-danger">*</span>
                            </label>

                            <div className="js-focus-state input-group form">
                              <input className="form-control form__input" name="result" value={this.props.result} type="text" required="" placeholder="Winner" onChange={this.props.leagueDataChange} />
                            </div>
                            <div id="resultError" className="invalid-feedback" style={{ display: "none" }}></div></div>
                      </div>
                      </div> 
                      </div>
        );
    }
}

export default CreateLeagueHistory;