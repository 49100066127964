import url from '../helpers/environment';
import { requestTeamData } from '../actions/teamDataActionCreator';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

toast.configure();
const axios = require('axios');
axios.defaults.baseURL = url


export const requestCreateTeam = (data) => async dispatch => {
    var createTeamData;
    axios.post('team', data).then(response => {
        createTeamData = response.data;
        dispatch({ type: 'RECIVED_CREATE_TEAM_SUCCESS', createTeamData });
        let userId = localStorage.getItem("user_id")
        dispatch(requestTeamData(userId))
    }).catch(error=> {
        if(error.response.status === 500){
            dispatch({ type: 'RECIVED_CREATE_TEAM_SUCCESS', createTeamData });
            let userId = localStorage.getItem("user_id")
            dispatch(requestTeamData(userId)) 
        }
        else{
            toast(error.response.data, { type: 'error' })
            dispatch({ type: 'RECIVED_CREATE_TEAM_FAILURE' });
        }
    });
    dispatch({ type: 'REQUESTED_CREATE_TEAM' });
}

export const requestGetTeamEditData = (teamId) => async dispatch => {
    axios.get('team/'+teamId).then(response => {
        var getTeamEditData = response.data;
        dispatch({ type: 'RECIVED_GET_TEAM_EDIT_DATA_SUCCESS', getTeamEditData });
    }).catch(error => {
        console.log(error)
        dispatch({ type: 'RECIVED_GET_TEAM_EDIT_DATA_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_GET_TEAM_EDIT_DATA' });
}

export const requestEditTeam = (data,teamId) => async dispatch => {
    axios.put('team/'+teamId,data).then(response => {
        var TeamEdit = response.data;
        dispatch({ type: 'RECIVED_TEAM_EDIT_SUCCESS', TeamEdit });
        let userId = localStorage.getItem("user_id")
        dispatch(requestTeamData(userId))
    }).catch(error => {
        toast(error.response.data, { type: 'error' })
        dispatch({ type: 'RECIVED_TEAM_EDIT_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_TEAM_EDIT' });
}

export const initialDefault = () => async dispatch => {
    dispatch({ type: 'DEFAULT_SCRIMMAGE' });
}

