import url from '../helpers/environment';
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { requestTeamData } from "../actions/teamDataActionCreator"
import { requestProfileData } from "../actions/profileActionCreator"
const axios = require('axios');
axios.defaults.baseURL = url

export const requestLogin = (loginData) => async dispatch => {
    axios.post('auth', loginData).then(response => {
        var accessToken = response.data;
        if(accessToken){
      localStorage.setItem('user_token', accessToken.accessToken)
      localStorage.setItem('user_name', accessToken.userName)
      localStorage.setItem('user_id', accessToken.userId)
      dispatch({ type: 'RECIVED_TOKEN_SUCCESS', accessToken });
      axios.defaults.headers.common['Authorization'] = 'bearer ' + accessToken.accessToken
      dispatch(requestTeamData(accessToken.userId))
      dispatch(requestProfileData(accessToken.userId))
        }

    }).catch(error => {
        console.log(error)
        var err = error.response.data;
        dispatch({ type: 'RECIVED_TOKEN_FAILURE', err });
    });
    dispatch({ type: 'REQUESTED_TOKEN' });
}

export const requestForgotPassword = (resetPassData) => async dispatch => {
    axios.post('reset-password', resetPassData).then(response => {
        dispatch({ type: 'RECIVED_FORGET_PASSWORD_SUCCESS' });
    }).catch(error => {
        console.log(error)
        dispatch({ type: 'RECIVED_FORGET_PASSWORD_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_FORGET_PASSWORD' });
}

export const requestResetPassword = (emailData) => async dispatch => {
    axios.post('send-password-reset-email', emailData).then(response => {
        let forgotPassStatus = response.status
        dispatch({ type: 'RECIVED_RESET_PASSWORD_SUCCESS', forgotPassStatus });
        toast('MESSAGE SENT\nPlease check your inbox.', { type: 'success' })
        //window.location.reload();
    }).catch(error => {
        console.log(error)
        if (error.response.status === 404) {
            toast.error("User email does not exist");
        }
        dispatch({ type: 'RECIVED_RESET_PASSWORD_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_RESET_PASSWORD' });
}

export const requestSignUp = (user) => async dispatch => {
    axios.post('user', user).then(response => {
        var signUpToken = response.data;
        dispatch({ type: 'RECIVED_USER_SIGN_UP_SUCCESS', signUpToken });
        const directLogin = {
            email: user.email,
            password: user.password
        }
        dispatch(requestLogin(directLogin))
    }).catch(error => {
        console.log(error)
        var signupError = error.response.data;
        dispatch({ type: 'RECIVED_USER_SIGN_UP_FAILURE', signupError });
    });
    dispatch({ type: 'REQUESTED_USER_SIGN_UP' });
}

export const initialDefault = () => async dispatch => {
    dispatch({ type: 'DEFAULT_SCRIMMAGE' });
}