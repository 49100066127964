import loginReducer from './loginReducer';
import profileReducer from './profileReducer';
import teamDataReducer from './teamDataReducer';
import scrimmageReducer from './scrimmageReducer';
import createTeamReducer from './createTeamReducer';
import createLeagueReducer from './createLeagueReducer';
import imageReducer from './imageReducer';
import { combineReducers } from 'redux';

const reducers = combineReducers({
    TokenState: loginReducer,
    ProfileState: profileReducer,
    TeamState: teamDataReducer,
    ImageState: imageReducer,
    ScrimmageState: scrimmageReducer,
    CreateTeamState: createTeamReducer,
    LeagueDataState:createLeagueReducer
});

export default reducers;

