import url from '../helpers/environment';
const axios = require('axios');
axios.defaults.baseURL = url


export const requestTeamData = (userId) => async dispatch => {
    axios.get('team?user='+userId).then(response => {
        var teamData = response.data;
        dispatch({ type: 'RECIVED_TEAM_DATA_SUCCESS', teamData });
    }).catch(error => {
        console.log(error)
        dispatch({ type: 'RECIVED_TEAM_DATA_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_TEAM_DATA' });
}

export const requestTeamCoverBG = (teamId, data) => async dispatch => {
    axios.put("team/" + teamId,data).then(response => {
        var teamCoverbg = response.data;
        dispatch({ type: 'RECIVED_TEAM_COVER_BG_SUCCESS', teamCoverbg });
        dispatch(requestTeamDetailsData(teamId))
    }).catch(error => {
        console.log(error)
        dispatch({ type: 'RECIVED_TEAM_COVER_BG_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_TEAM_COVER_BG' });
}

export const requestTeamDetailsData = (teamDetailsId) => async dispatch => {
    axios.get("team/" + teamDetailsId).then(response => {
        var teamDetailsData = response.data;
        dispatch({ type: 'RECIVED_TEAM_DETAILS_DATA_SUCCESS', teamDetailsData });
    }).catch(error => {
        console.log(error)
        dispatch({ type: 'RECIVED_TEAM_DETAILS_DATA_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_TEAM_DETAILS_DATA' });
}
