
const defaultTeamDataState = {
    teamData: null,
    teamDetailsData: null,
    isLoading: false,
    status:0
};

export default function (state = defaultTeamDataState, action) {

    switch (action.type) {
        case 'DEFAULT_SCRIMMAGE':
            return { ...state, isLoading: false, status: 0, forgotPassStatus:null, };

        case 'REQUESTED_TOKEN':
            return { ...state, isLoading: true , status : 1};
        case 'RECIVED_TOKEN_FAILURE':
            return { ...state,  err:action.err, isLoading: false, status : 2 };
        case 'RECIVED_TOKEN_SUCCESS':
            localStorage.setItem('LOGIN_TOKEN', action.accessToken)

            return { ...state, token: action.accessToken, isLoading: false , status : 3};
        
        case 'REQUESTED_TEAM_DATA':
            return { ...state, isLoading: true };
        case 'RECIVED_TEAM_DATA_SUCCESS':
            return { ...state, teamData: action.teamData, isLoading: false };
            case 'RECIVED_TEAM_DATA_FAILURE':
                return { ...state, isLoading: false };
            

        case 'RECIVED_TEAM_DETAILS_DATA':
            return { ...state, isLoading: true };
        case 'RECIVED_TEAM_DETAILS_DATA_SUCCESS':
            return { ...state, teamDetailsData: action.teamDetailsData, isLoading: false };
            case 'RECIVED_TEAM_DETAILS_DATA_FAILURE':
                return { ...state, isLoading: false };
        default:
    }

    return state
}