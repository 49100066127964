import React, { Component } from 'react';

class Info extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }
  render() {
    return (
      <>
      <h5>Team Skill Level 1</h5>
      <p className="font-size-14 text-lh-sm">These teams are newly formed and have players just learning the game. There are no tryouts for this level. Teams are still learning the rules and may participate in an instructional league. Players are typically friends from school that want to play together. These teams do not continue to play after the season.</p>
      
      <h5>Team Skill Level 2</h5>
      <p className="font-size-14 text-lh-sm">Teams at this level do not hold tryouts and are made up of players from the same school district who are friends and enjoy playing with each other. Teams will play at a recreational, CYO level and practice 1-2 times a week. This level teams do not play once the season is complete.</p>

      <h5>Team Skill Level 3</h5>
      <p className="font-size-14 text-lh-sm">Teams in this level possibly have tryouts and may have players from a different school district than the majority of players. Teams practice 1-2 times a week. Players play multiple sports throughout the year and teams do not typically play after the season is complete.</p>

      <h5>Team Skill Level 4</h5>
      <p className="font-size-14 text-lh-sm">Teams in this level hold tryout and practice 1-2 times a week. They will attract players from different school districts and zip codes within their region. Teams will play a reduced schedule after their primary season is over. Teams will have players that play multiple sports throughout the year.</p>

      <h5>Team Skill Level 5</h5>
      <p className="font-size-14 text-lh-sm">These teams also play a National schedule but one notch below Level-6 teams. These teams also have players from multiple areas and possibly out of state. These teams have tryouts and practice 2-3 times a week and play throughout the year. These teams are usually a part of a larger organization. Teams at this level will have some players that will potentially play at the Division 1, 2 and 3 college level.</p>

      <h5>Team Skill Level 6</h5>
      <p className="font-size-14 text-lh-sm">Teams in level 6 compete in National tournaments and or compete on a sponsored circuit. These teams will have players from multiple areas and states. Teams will typically be a part of a larger organization that plays all year around. Many players on these teams go on to play at the Division 1 college level.</p>
      </>
    );
  }
}

export default Info;