import React, { Component } from 'react';
import Slider from "react-slick";

class Carousel extends Component {
  render() {
    const settings = {
      fade: true,
      speed: 2500,
      autoplay: true,
      autoplaySpeed:6000
    };
    return (
        <Slider {...settings}>
          <div className="bg-img-hero needs-gamefinder1"></div>
          <div className="bg-img-hero needs-gamefinder2"></div>
          <div className="bg-img-hero needs-gamefinder3"></div>
          <div className="bg-img-hero needs-gamefinder4"></div>
          <div className="bg-img-hero needs-gamefinder5"></div>
        </Slider>
    );
  }
}

class WhyGameFinder extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
      
      <div className="space-1-bottom">

         <div className="container">
           <div className="text-center">
             <h2 className="mt-5 w-lg-80 mx-md-auto">In the United States today, there are 44 million team sports participants between the ages of 6 and 17.</h2>
             <hr/>
             <p className="w-lg-90 mx-md-auto">A growing number  of those youth athletes play travel or club sports which are becoming increasing inaccessible due to mounting travel costs and tournament fees.</p>
           </div>
         </div>
         <div className="position-relative space-1--lg mt-lg-9">
          <div className="container">
            <div className="col-lg-5 position-absolute-top-left-0--lg why-carousel">
              <Carousel/>
            </div>
            <div className="row justify-content-lg-end align-self-lg-center">
              <div className="col-lg-6 mt-5 mt-lg-0">
                <h2 className="h2 font-weight-black">Why GameFinder365?</h2>
                <br/>
                <p><span className="font-weight-bold">The Coach:</span> GF365 eliminates the time coaches waste  calling, emailing and texting other coaches to find and schedule games and scrimmages.</p>
                <p><span className="font-weight-bold">The Parent:</span> Parents need an alternative to spending weekends driving kids to games or tournaments, just to find that the games are delayed, the athletes are playing a far inferior/superior team, or the location of the game has been changed, all while spending money on entry fees, gas, and food.</p>
                <p><span className="font-weight-bold">The Athlete:</span> Athletes would much rather play games than just a normal practice - scrimmaging is the highlight of any practice even if against their own teammates.  GF365 is answering every player’s call to play more games!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 
      <div className="space-1-bottom gradient-overlay-half-dark-v3 bg-img-hero home-background">
          <div className="position-relative space-2-top text-center">
            <div className="container">
              <div className="w-lg-75 mx-md-auto">
                <h2 className="font-weight-black text-white">Real Life Comparison</h2>
                <p className="font-weight-bold text-white">Chart 1: GF365 vs. Local Tournaments:</p>
                <p className="text-white">The coach of a 13 year-old girls travel basketball team seeks to improve their team by playing against other teams in preparation for upcoming league play and competitive regional and national tournaments.  Below are two scenarios using GF365 to schedule scrimmages vs. signing up for a locally-run tournament 45 minutes away.</p>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            
            <div className="row mx-auto w-md-90 mx-3 px-md-0">
              
              <div class="col-sm-12 col-md-6 mb-4">
                <div className="card h-100">
                  <header className="card-header text-center p-5">
                    <h3 className="custom-color font-weight-black mb-0"><span className="gamefinder">GF</span> 365 platform</h3>
                  </header>
                  <div className="card-body p-5">
                    <ul>
                      <li className="mb-3 font-size-base text-lh-sm">The team has a 2 hour practice slot at a local school</li>
                      <li className="mb-3 font-size-base text-lh-sm">Coach sends out a request on GF365 looking to scrimmage during her set practice time</li>
                      <li className="mb-3 font-size-base text-lh-sm">6 teams respond; 3 of the teams are of a similar skill level</li>
                      <li className="mb-3 font-size-base text-lh-sm">Coach confirms scrimmage details with those 3 teams via the platform</li>
                      <li className="mb-3 font-size-base text-lh-sm">Upon arrival each team has its own basket to practice/warm-up for 30 minutes</li>
                      <li className="mb-3 font-size-base text-lh-sm">Each team scrimmages for 30 minutes against each of the other 3 teams</li>
                      <li className="mb-3 font-size-base text-lh-sm">REMOVE THIS>>> Membership for the platform is $205 for the year or $17/ month. For less than the entry fees for one day of an event, teams can play an unlimited number of scrimmages</li>
                    </ul>
                    <hr/>
                    <h5 className="text-center font-weight-bold">FINAL OUTCOME:</h5>
                    <hr/>
                    <p className="font-size-18 text-lh-sm">Each team played against equally-matched competition, improving their skills and preparing them for regional/national exposure competitions. Families minimized lost weekend time and the financial impact on the families and team was minimal.</p>
                    <p className="text-center">REMOVE THIS>>> <a href="/pricing" className="btn btn-lg btn-secondary">View Pricing Plans</a></p>
                  </div>
                </div>
              </div>
              
              <div class="col-sm-12 col-md-6 mb-4">
                <div className="card card2 h-100">
                  <header className="card-header card-header2 text-center p-5">
                    <h3 className="text-muted font-weight-black mb-0">Local tournament</h3>
                  </header>
                  <div className="card-body p-5">
                    <ul>
                      <li className="mb-3 font-size-base text-lh-sm">Team tournament entry fee for 3 games = $375</li>
                      <li className="mb-3 font-size-base text-lh-sm">Entry fee for spectators is $10 per person * 20 people for both Saturday and Sunday = $400.00</li>
                      <li className="mb-3 font-size-base text-lh-sm">Food for the two days is an average of $20 per family per day for 10 families = $400.00</li>
                      <li className="mb-3 font-size-base text-lh-sm">Gas for two days assuming a 40 mile round-trip per day per family is $10 per family (plus the coach) = $220</li>
                    </ul>
                    <hr/>
                    <h5 className="text-center font-weight-bold">FINAL OUTCOME:</h5>
                    <hr/>
                    <p className="font-size-18 text-lh-sm">The team won or lost their three games by an average of 30 points because entry was open to all levels. The event was poorly run because of the lack of communication from the tournament organizers. Team spent $1,395 and dedicated 16 hours out of their 48 hour weekend, ultimately reaping little benefit. Market research indicates that teams will play in an average of four local tournaments a season.</p>
                  </div>
                </div>
              </div>
              
              
            </div>
          </div>
       </div>
       */}
      </>
    );
  }
}

export default WhyGameFinder;