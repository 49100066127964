import React, { Component } from 'react';
import { connect } from 'react-redux';
import { apiUrl } from '../../config';
import axios from "axios";
import { requestSignUp } from '../../actions/loginActionCreator';


class Signup extends Component {
  constructor(props) {
    super(props);
    axios.defaults.baseURL = apiUrl;
    this.state = {
      email: "",
      password: "",
      confirmpassword: "",
      firstName: "",
      lastName: "",
      class: "u-video-player",
      emailError: "",
      passwordError: "",
      confirmPasswordError: "",
      firstNameError: "",
      lastNameError: ""
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value })
  }

  playVideo = () => {
    this.setState({
      class: "u-video-player u-video-player__played"
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.details){
      localStorage.setItem('user_token',  nextProps.details && nextProps.details.accessToken)
      localStorage.setItem('user_name',  nextProps.details &&  nextProps.details.userName)
      localStorage.setItem('user_id',  nextProps.details &&  nextProps.details.userId)
    }

    if (nextProps.token.status === 3) {
      this.props.history.push('/addteam');
    }

    if(nextProps.SignUpState.status === 2){
        this.setState({
          emailError: nextProps.SignUpState.signupError
        })
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    let { email,firstName,lastName,password,confirmpassword } = this.state;
    if (email && firstName && lastName && password && confirmpassword) {
      const isValidEmail = this.validateEmail(email);
      if (isValidEmail) {
        this.setState({ emailError: "" });
        if (this.state.password === this.state.confirmpassword) {
          const data = {
            email: this.state.email,
            password: this.state.password,
            firstname:this.state.firstName,
            lastname:this.state.lastName
          }
          this.props.requestSignUp(data);
        }
        else{
          this.setState({
            confirmPasswordError: "Password is not matched"
          })
        }
      }
    }
    else {
      this.setState({
        emailError: "This field is required"
      })
    }
    if(this.state.email === ""){
      this.setState({ emailError: "This field is required" });
    }
    else{
      this.setState({ emailError: "" });
    }
    if (this.state.password === "") {
      this.setState({
        passwordError: "This field is required"
      })
    }
    else {
      this.setState({
        passwordError: ""
      })
    }
    if (this.state.confirmpassword === "") {
      this.setState({
        confirmPasswordError: "This field is required"
      })
    }
    else {
      this.setState({
        confirmPasswordError: ""
      })
    }
    if (this.state.firstName === "") {
      this.setState({
        firstNameError: "This field is required"
      })
    }
    else {
      this.setState({
        firstNameError: ""
      })
    }
    if (this.state.lastName === "") {
      this.setState({
        lastNameError: "This field is required"
      })
    }
    else {
      this.setState({
        lastNameError: ""
      })
    }
  };


    validateEmail = (email) => {
      let emailError = "";
      const pattern = /[a-zA-Z0-9]+[.]?([a-zA-Z0-9]+)?[@][a-zA-Z0-9]{3,20}[.][a-zA-Z]{2,5}/g;
      const result = pattern.test(email);
      if (result === true) {
        emailError = "Correct";
        return true;
      } else {
        emailError = "Invalid Email";
      }
      if (emailError) {
        this.setState({ emailError });
        return false;
      }
      return true;
    }

  render() {
    return (
      <div>
        <div className="gradient-overlay-half-dark-v3 bg-img-hero login-background">
          <div className="align-items-lg-center height-100vh">
            <div className="container space-2">
              <div className="row justify-content-lg-between align-items-lg-center">
                <div className="col-lg-7 m-auto">
                  <div className="bg-white shadow-sm rounded p-6">
                    <form className="js-validate" noValidate="noValidate" onSubmit={this.handleSubmit}>
                      <div className="mb-4">
                        <h2 className="text-center font-weight-bold">Please Sign Up</h2>
                        <p className="text-center">Fill out the form &amp; verify your email address.</p>
                      </div>

                      <div className="js-form-message mb-3">
                        <div className="js-focus-state input-group input-group form">
                          <div className="input-group-prepend form__prepend">
                            <span className={this.state.emailError ? "input-group-text form__text error" : "input-group-text form__text"}>
                              <img src="/img/login-and-signup/email.png" className="phone" alt="" />
                            </span>
                          </div>
                          <input type="email" className={this.state.emailError ? "form-control form__input error" : "form-control form__input"} name="email" value={this.state.email} placeholder="Email" aria-label="Enter your email address" onChange={this.handleChange} />
                        </div>
                        {
                          this.state.emailError ? <div style={{ color: "red" }}>{this.state.emailError}</div> : null
                        }
                      </div>
                      <div className="js-form-message mb-3">
                        <div className="js-focus-state input-group input-group form">
                          <div className="input-group-prepend form__prepend">
                            <span className={this.state.passwordError ? "input-group-text form__text error" : "input-group-text form__text"}>
                              <img src="/img/login-and-signup/pass.png" className="phone" alt="" />
                            </span>
                          </div>
                          <input type="password" className={this.state.passwordError ? "form-control form__input error" : "form-control form__input"} name="password" value={this.state.password} placeholder="Password" aria-label="Enter your password" onChange={this.handleChange} />
                        </div>
                        {
                          this.state.passwordError ? <div style={{ color: "red" }}>{this.state.passwordError}</div> : null
                        }
                      </div>
                      <div className="js-form-message mb-3">
                        <div className="js-focus-state input-group input-group form">
                          <div className="input-group-prepend form__prepend">
                            <span className={this.state.confirmPasswordError ? "input-group-text form__text error" : "input-group-text form__text"}>
                              <img src="/img/login-and-signup/repeat-pass.png" className="phone" alt="" />
                            </span>
                          </div>
                          <input type="password" className={this.state.confirmPasswordError ? "form-control form__input error" : "form-control form__input"} name="confirmpassword" value={this.state.confirmpassword} placeholder="Confirm Password" aria-label="Confirm Password" onChange={this.handleChange} />
                        </div>
                        {
                          this.state.confirmPasswordError ? <div style={{ color: "red" }}>{this.state.confirmPasswordError}</div> : null
                        }
                      </div>
                      <div className="js-form-message mb-3">
                        <div className="js-focus-state input-group input-group form">
                          <div className="input-group-prepend form__prepend">
                            <span className={this.state.firstNameError ? "input-group-text form__text error" : "input-group-text form__text"}>
                              <img src="/img/login-and-signup/info.png" className="info" alt="" />
                            </span>
                          </div>
                          <input type="text" className={this.state.firstNameError ? "form-control form__input error" : "form-control form__input"} name="firstName" value={this.state.firstName} placeholder="First Name" onChange={this.handleChange} />
                        </div>
                        {
                          this.state.firstNameError ? <div style={{ color: "red" }}>{this.state.firstNameError}</div> : null
                        }
                      </div>
                      <div className="js-form-message mb-3">
                        <div className="js-focus-state input-group input-group form">
                          <div className="input-group-prepend form__prepend">
                            <span className={this.state.lastNameError ? "input-group-text form__text error" : "input-group-text form__text"}>
                              <img src="/img/login-and-signup/info.png" className="info" alt="" />
                            </span>
                          </div>
                          <input type="text" className={this.state.lastNameError ? "form-control form__input error" : "form-control form__input"} name="lastName" value={this.state.lastName} placeholder="Last Name" onChange={this.handleChange} />
                        </div>
                        {
                          this.state.lastNameError ? <div style={{ color: "red" }}>{this.state.lastNameError}</div> : null
                        }
                      </div>
                      <button type="submit" className="btn btn-block btn-primary">Sign Up</button>
                    </form>
                    <div className="text-center my-2">
                      <span>Have an account? <a href="/login" className="custom-color">Log In</a></span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return{
  token:state.TokenState,
  details:state.TeamState.token,
  SignUpState: state.TokenState
  }};

const mapDispatchToProps = {
  requestSignUp
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup)