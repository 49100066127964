import React, { Component } from 'react';
import Mailchimp from 'react-mailchimp-form';

function Subscribe() {
  return (
    <>
      <div className="d-inline text-center text-md-right">
        <Mailchimp
          action="https://Gamefinder365.us19.list-manage.com/subscribe/post?u=6c18da9605a26e3485b53a53b&amp;id=311412ddbe"
          fields={[
            {
              name: 'EMAIL',
              placeholder: 'Enter Email Address',
              type: 'email',
              required: true
            }			
          ]}
          messages = {
            {
              sending: 'Sending...',
              success: 'Thank you for subscribing!',
              error: 'An unexpected internal error has occurred.',
              empty: 'Please provide a valid email address.',
              duplicate: 'Too many subscribe attempts for this email address.',
              button: 'Subscribe'
            }  
          }
          className='mailchimp form'
        />
      </div>
    </>
  )
}

class Footer extends Component {  
  render() {
    return (
      <div>
	<div className="container-fluid footer py-3">
	  <div className="row">
	    <div className="col-12 col-md-6 text-center text-md-left font-size-13 text-lh-sm">
	      <div>
	      	Questions? <a href="/contact">Contact GameFinder365</a>
	      </div>
	      &copy; {new Date().getFullYear()} GameFinder365. All rights reserved.
	    </div>
	    <div className="col-12 col-md-6 mt-5 mt-md-0">
	      <Subscribe />
	    </div>
	  </div>
	</div>
      </div>
    );
  }
}

export default Footer;