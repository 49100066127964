import React, { Component } from 'react';
import { Link } from "react-router-dom"

class Pricing extends Component {
constructor(props) {
    super(props);
  }

  render() {

    return (
      <>
        <div className="login-background gradient-overlay-half-dark-v3 pricing-bg">
          <div className="container space-1">
            <div className="w-lg-60 text-center mx-md-auto">
              <h2 className="text-white price-heading font-weight-bold"><span className="gamefinder font-weight-black">GF</span><span className="custom-color font-weight-black">365</span> is offering our initial coaches a FREE introductory trial.</h2>
            </div>
            <div className="row align-items-center justify-content-center m-auto">
	      <div className="my-3 w-lg-25">
  	      <div className="bg-white border rounded-lg p-5 stripe">
	        {
	          localStorage.getItem("user_id")=== null ?
	          <div className="text-center">
	            <div className="mb-2">
		      {/* <h6 className="text-center text-uppercase mb-0"><b>3-MONTH TRIAL</b></h6> */} 
		      <h2 className="mb-2 text-center price-color font-weight-bold price-amount">FREE TRIAL</h2>
		    </div>
		    <Link to="/signup"><button type="button" className="btn btn-primary btn-lg font-weight-bold">Sign Up Today</button></Link>
	      	    <div className="mt-2 text-lineheight-normal"><small>OFFER AVAILABLE FOR A LIMITED TIME</small></div>
		  </div>
		  :
	          <div className="text-center">
		    <div className="mb-2 font-weight-bold">You are signed in.</div>
		    <Link to="/yourteam"><button type="button" className="btn btn-primary btn-lg font-weight-bold">View My Teams</button></Link>
		  </div>
		}
	      </div>
	    </div>
            </div>
            <div className="w-lg-50 text-center mx-md-auto">
              <h5 className="text-white mt-3 font-weight-bold">With this trial, coaches will have the ability to:</h5>
	      <ul className="text-white">
	        <li className="text-white">Create multiple team profiles</li>
	        <li className="text-white">Send and receive unlimited requests for scrimmages and games</li>
	       </ul>
            </div>
          </div>
        </div>
        <div className="container">
            <div className="row text-center my-5">
              <div className="mx-auto">
                <h5 className="font-weight-bold">Throughout the beta period we will be welcoming and incorporating customer feedback.</h5>
                <h5>Please send your comments and questions to <a href="mailto:info@gamefinder365.com?subject=Beta%20Site%20Feedback" className="font-weight-bold">info@gamefinder365.com</a></h5>
              </div>
            </div>
        </div>

      </>
    );
  }
}

export default Pricing;