import React from "react";
import Nav from "../Nav";
import Footer from "../Footer";
class PublicLayout extends React.Component {
  render(props) {
    return (
    <>
     <Nav />
    {this.props.children}
     <Footer /> 
    </>
    )
  }
}

export default PublicLayout;
