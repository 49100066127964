import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import axios from 'axios';
import { apiUrl } from '../../config';
import Spinner from '../component/Spnner';
import { FaArrowLeft,FaArrowRight } from 'react-icons/fa'
import { requestScrimmageData, requestScrimmageDecline, requestScrimmageAccept,initialDefault } from '../../actions/scrimmageActionCreator'

let hide = "";
let invitationUrl = "";
let teamId = "";

class ScrimmageInvitation extends Component {
    constructor(props) {
        super(props);
        axios.defaults.baseURL = apiUrl;
        this.state = {
            isReferee: "",
            token: "",
            id: "",
            requestId: "",
            refereeRequestError: "",
            leagueHistory:[]
        };
    }


    componentDidMount() {
        if(window.location.pathname.split("/")[3] !== localStorage.getItem("user_id")){
            this.props.history.push("/login")
        }
        else{
            invitationUrl = (window.location.search.split("&"));
            teamId = invitationUrl[1].split("=")
            this.props.initialDefault();
            let url = window.location.pathname.split("/");
            let searchId = window.location.search.split("=");
            hide = searchId[2] && searchId[2].split("&")[1];
            let token = searchId[1] && searchId[1].split("&");
            this.setState({
                token: token && token[0],
                id: url[2],
                requestId: searchId[3],
            }, () => {
                this.getScrimmageDetails();
            })
        }
    }

    getScrimmageDetails = () => {
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.state.token
        this.props.requestScrimmageData(this.state.id);
    }

    handleRejectScrimmage = () => {
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.state.token
        const declineData = {
            refereeRequest: null,
            status: "Rejected"
        }
        this.props.requestScrimmageDecline(this.state.requestId, declineData)
    }

    handleAcceptScrimmage = () => {
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.state.token
        const acceptData = {
            refereeRequest: this.state.isReferee,
            status: "Accepted"
        }
        if (this.props.scrimmageData.ScrimmageState.scrimmageInvitationDataByMail && this.props.scrimmageData.ScrimmageState.scrimmageInvitationDataByMail.refereeRequest === "yes") {
            this.props.requestScrimmageAccept(this.state.requestId, acceptData)
        }
        else {
            this.props.requestScrimmageAccept(this.state.requestId, acceptData)
        }

    }

    handleReferee = (e) => {
        this.setState({
            isReferee: e.target.value
        })
    }
    componentWillReceiveProps = (nextProps) => {
        this.setState({
            leagueHistory:nextProps.leagueData
        })
        if (nextProps.scrimmageData.ScrimmageState && nextProps.scrimmageData.ScrimmageState.status === 3) {
            this.props.history.push(`/teamdetails/${teamId[1]}`)
        }
      }

    render() {        
        let userTeamName = this.props.teamData.TeamState.teamDetailsData && this.props.teamData.TeamState.teamDetailsData.name
        let teamIdBack = this.props.teamData.TeamState.teamDetailsData && this.props.teamData.TeamState.teamDetailsData.id

        let scrimmageInvitationData = this.props.scrimmageData.ScrimmageState.scrimmageInvitationDataByMail && this.props.scrimmageData.ScrimmageState.scrimmageInvitationDataByMail;
        let date = scrimmageInvitationData && scrimmageInvitationData.startDate
        let cutoff = scrimmageInvitationData && scrimmageInvitationData.cutoffDate
        let newDate = (new Date(Date.parse(date))).toLocaleDateString();
        let cutoffdate = (new Date(Date.parse(cutoff))).toLocaleDateString();
        let newTime = ((new Date(Date.parse(date))).toLocaleTimeString(navigator.language,{hour: '2-digit', minute:'2-digit'}))
        let gameDuration = scrimmageInvitationData && scrimmageInvitationData.duration.split(":").slice(0,-1).join(':');
        let price = scrimmageInvitationData && scrimmageInvitationData.priceRange;
        let priceRange = price && price.split("-")
        let download = scrimmageInvitationData && scrimmageInvitationData.attachments[0];
        return (
            <div>
                 <Spinner display={this.props.scrimmageData.ScrimmageState.isLoading}>
                    <div className="container-fluid">
        <div className="row justify-content-lg-between align-items-lg-start createScrimmmage">
            <div className="col header-text pt-7 pb-3">
              <h2 className="text-white">Your scrimmage invitation</h2>
            </div>
          </div>
        </div>
                <div className="container space-2-top">
                        
                            {
                                scrimmageInvitationData && scrimmageInvitationData.matchCriteria.strength.map((data,key)=>{
                                    return(




				<div>
				    <div className="row pb-3">
				        <div className="col-8">
				            <label className="h2">For Team:</label> <span className="font-weight-black h2">{userTeamName}</span>
				        </div>
				        <div className="col-4 text-right">
				            <Link to={"/teamdetails/" + teamIdBack}>
				                Back to Team &nbsp; <FaArrowRight />
				            </Link>
				        </div>
				    </div>

				    <div className="row align-items-lg-start mt-5">
				        <div className="col-12 col-md-4">
					    <div className="mb-7">
					        <div className="js-form-message mb-6 u-has-success">
					    	    <label className="small d-block text-uppercase">
						        Opponent Team Level/Age Group
					            </label>
						    <p className="rounded border p-2 border-color bg-gray-100">{data && data.ageGroup}</p>
					        </div>
					    </div>
				        </div>
				        <div className="col-12 col-md-4">
					    <div className="mb-7">
					        <div className="js-form-message mb-6 u-has-success">
						    <label className="small d-block text-uppercase">
						        Opponent Team Skill Level (Min)
					            </label>
						    <p className="rounded border p-2 border-color bg-gray-100">{data && data.minSkill}</p>
					        </div>
					    </div>
				        </div>
				        <div className="col-12 col-md-4">
					    <div className="mb-7">
					        <div className="js-form-message mb-6 u-has-success">
						    <label className="small d-block text-uppercase">
						        Opponent Team Skill Level (Max)
					            </label>
						    <p className="rounded border p-2 border-color bg-gray-100">{data && data.maxSkill}</p>
					        </div>
					    </div>
				        </div>
				    </div>
				</div>
                                    )
                                })
                            }
               
                       
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <div className="mb-7">
                                    <div className="js-form-message mb-6 u-has-success">
                                        <label className="small d-block text-uppercase">
                                            Date
                                    </label>
                                        <p className="rounded border p-2 border-color bg-gray-100">{newDate}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="mb-7">
                                    <div className="js-form-message mb-6 u-has-success">
                                        <label className="small d-block text-uppercase">
                                            Game Time
                                    </label>
                                        <p className="rounded border p-2 border-color bg-gray-100">{newTime}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="mb-7">
                                    <div className="js-form-message mb-6 u-has-success">
                                        <label className="small d-block text-uppercase">
                                            Number of teams Desired
                                    </label>
                                        <p className="rounded border p-2 border-color bg-gray-100">{scrimmageInvitationData && scrimmageInvitationData.teamCount}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <div className="mb-7">
                                    <div className="js-form-message mb-6 u-has-success">
                                        <label className="small d-block text-uppercase">
                                            Game Duration
                                    </label>
                                        <p className="rounded border p-2 border-color bg-gray-100">{scrimmageInvitationData && gameDuration}</p>
                                    </div>
                                </div>
                            </div>
                            {/*
                            <div className="col-12 col-md-4">
                                <div className="mb-7">
                                    <div className="js-form-message mb-6 u-has-success">
                                        <label className="small d-block text-uppercase">
                                            Waiver for your Team
                                    </label>
                                        <div className="custom-file">
                                            <a href={download !==undefined ? download.url:"#" } download={download && download.url} target={download !== undefined ?'_blank':""}>
                                                <label className="custom-file-label text-muted download" for="inputGroupFile01">
                                                    <p>
                                                        {download && download.name}
                                                    </p>
                                                </label>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            */}
                            <div className="col-12 col-md-4">
                                <div className="mb-7">
                                    <div className="js-form-message mb-6 u-has-success">
                                        <label className="small d-block text-uppercase">
                                            Team Gender
                                    </label>
                                        <p className="rounded border p-2 border-color bg-gray-100 text-capitalize">{scrimmageInvitationData && scrimmageInvitationData.matchCriteria.gender}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="mb-7">
                                    <div className="js-form-message mb-6 u-has-success">
                                        <label className="small d-block text-uppercase">
                                            Venue Name
                                    </label>
                                        <p className="rounded border p-2 border-color bg-gray-100">{scrimmageInvitationData && scrimmageInvitationData.location.name}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <div className="mb-7">
                                    <div className="js-form-message mb-6 u-has-success">
                                        <label className="small d-block text-uppercase">
                                            Venue Address
                                    </label>
                                        <p className="rounded border p-2 border-color bg-gray-100">{scrimmageInvitationData && scrimmageInvitationData.location.address1}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="mb-7">
                                    <div className="js-form-message mb-6 u-has-success">
                                        <label className="small d-block text-uppercase">
                                            Venue City
                                    </label>
                                        <p className="rounded border p-2 border-color bg-gray-100">{scrimmageInvitationData && scrimmageInvitationData.location.city}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="mb-7">
                                    <div className="js-form-message mb-6 u-has-success">
                                        <label className="small d-block text-uppercase">
                                            Venue State
                                    </label>
                                        <p className="rounded border p-2 border-color bg-gray-100">{scrimmageInvitationData && scrimmageInvitationData.location.state}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <div className="mb-7">
                                    <div className="js-form-message mb-6 u-has-success">
                                        <label className="small d-block text-uppercase">
                                            Zip Code
                                    </label>
                                        <p className="rounded border p-2 border-color bg-gray-100">{scrimmageInvitationData && scrimmageInvitationData.location.zipCode}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="mb-7">
                                    <div className="js-form-message mb-6 u-has-success">
                                        <label className="small d-block text-uppercase">
                                            Responds By
                                    </label>
                                        <p className="rounded border p-2 border-color bg-gray-100">{cutoffdate}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div>
                                    <label className="small d-block text-uppercase">
                                        THIS SCRIMMAGE WILL HAVE A REFEREE
				    </label>
				    <div className="row">
				    {
					window.location.search.split("=")[4] && window.location.search.split("=")[4]!=="null" ?
					(  
					<>
					<div className="col-6 col-md-3">
					    <span className="d-block h6 mb-1 custom-color">{window.location.search.split("=")[4]}</span>
				        </div>
				    </>
				    )
					:
					(   

					<>
				        <div className="col-6 col-md-3">
					    <div className="custom-control custom-radio custom-control-inline form-selector-modern">
					        <input type="radio" id="referee1" className="custom-control-input form-selector-modern__input" name="productShippingSelector" checked={scrimmageInvitationData && scrimmageInvitationData.refereeRequest ==="no" ? true:false} value="no" disabled onClick={this.handleReferee} />
					        <label className="custom-control-label form-selector-modern__label" for="referee1">
						    <span className="d-block h6 mb-1">No</span>
					        </label>
					    </div>
				        </div>
				        <div className="col-6 col-md-3">
					    <div className="custom-control custom-radio custom-control-inline form-selector-modern">
					        <input type="radio" id="referee" className="custom-control-input form-selector-modern__input" name="productShippingSelector" checked={scrimmageInvitationData && scrimmageInvitationData.refereeRequest ==="yes" ? true:false} value="yes" disabled onClick={this.handleReferee} />
					        <label className="custom-control-label form-selector-modern__label" for="referee">
					    	    <span className="d-block h6 mb-1">Yes</span>
					        </label>
					    </div>
				        </div>
				    </>
				    )
				    }

				    {
					this.state.refereeRequestError ? <div style={{ color: "red" }}>{this.state.refereeRequestError}</div> : null
				    }
			            </div>
                                </div>
                            </div>
                        </div>
                            
                        <div className="row">
                            <div className="col-12 col-md-8">
                                <div className="mb-7">
                                    <div className="js-form-message mb-6 u-has-success">
                                        <label className="small d-block text-uppercase">
                                            Special Instructions
                                         </label>
                                        <p className="rounded border p-2 border-color bg-gray-100 special">{scrimmageInvitationData && scrimmageInvitationData.description}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
				<div className="col-12">
				    <label className="small d-block text-uppercase">Cost range per team</label>
				</div>
                                <div className="row">
			            <div className="col-5">
				        <p className="rounded border p-2 border-color bg-gray-100 cost-height">$ {priceRange && priceRange[0]}</p>
			            </div>
			            <div className="col-1">
				        -
			            </div>
			            <div className="col-5">
				        <p className="rounded border p-2 border-color bg-gray-100 cost-height">$ {priceRange && priceRange[1]}</p>
			            </div>
			         </div>
			    </div>

			    <div className="row">
				<div className="col-12">
				    <p className="btn btn-primary text-white" data-toggle="modal" data-target="#scrimmagehistory">View League/Tournament History</p>
				</div>
			    </div>
                            <div className="col-12 mb-7">
                                <hr />
                                <div className="d-flex justify-content-sm-between align-items-sm-center">
                                    <a href={"/teamdetails/" + teamIdBack} className="d-block mb-3 mb-sm-0 custom-color">
                                        <FaArrowLeft /> &nbsp;
                                            Back to Team
                                    </a>
                                    {
                                        hide !== "hide" ?
                                            (<div className="mr-0 ml-auto d-flex">
                                                <div className="mx-1"><button type="button" className="btn btn-outline-light" onClick={this.handleRejectScrimmage}>Reject</button></div>
                                                <div className="mx-1"><button type="button" className="btn btn-primary" onClick={this.handleAcceptScrimmage}>Accept</button></div>
                                            </div>)
                                            :
                                            null
                                    }

                                </div>
                            </div>
                        </div>
                        {/* Modal */}
                        <div className="modal fade" id="scrimmagehistory" tabindex="-1" role="dialog" aria-labelledby="scrimmagehistory" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-dialog-view-history" role="document">
                                <div className="modal-content view-history">
                                    <div className="modal-header">
                                        <h2 className="text-center font-weight-bold">Your League/Tournament History</h2>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {
                                            this.state.leagueHistory && this.state.leagueHistory.map((data,key)=>{
                                                return(
                                                    <>
                                                    <div className="row" key={key}>
                                                    <div className="col-12 d-flex">
                                                        <div>
                                                        <p className="font-weight-bold mb-1">{data.name}</p>
                                                            <p>{data.result}</p>
                                                        </div>
                                                        <div className="ml-auto">
                                                            <p className="font-weight-bold">{data.season}, {data.year}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <p>{data.description}</p>
                                                    </div>
                                                </div>
                                                 <hr />
                                                 </>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                </Spinner>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        scrimmageData: state,
    	teamData: state,
        leagueData: state.LeagueDataState.getLeagueData,
        isLoading:state.ScrimmageState.isLoading
    }
};

const mapDispatchToProps = {
    initialDefault,
    requestScrimmageData,
    requestScrimmageDecline,
    requestScrimmageAccept
    
};
export default connect(mapStateToProps, mapDispatchToProps)(ScrimmageInvitation)
