const ENV = {
     prod: {
        apiUrl: 'https://gamefinder365-api.azurewebsites.net/',
        notice: 'PROD',
    },
    dev: {
        apiUrl: 'https://gamefinder365-api-dev.azurewebsites.net/',
        notice: 'DEV',
    }
}
 
function getEnvVars(env = '') {
    if (env.indexOf('dev') !== -1) return ENV.dev
    if (env.indexOf('prod') !== -1) return ENV.prod
 
    return ENV.prod
}
 
export default getEnvVars(process.env.ENVIRONMENT)