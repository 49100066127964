import url from '../helpers/environment';
import { requestTeamData } from '../actions/teamDataActionCreator';
import { requestLeagueData } from '../actions/createLeagueActionCreator';
const axios = require('axios');
axios.defaults.baseURL = url

export const requestScrimmage = (data, teamID) => async dispatch => {
    axios.post('game/team/' + teamID, data).then(response => {
        var scrimmageData = response.data;
        dispatch({ type: 'RECIVED_CREATE_SCRIMMAGE_SUCCESS', scrimmageData });
        let userId = localStorage.getItem("user_id")
        localStorage.setItem("name","Outgoing")
        dispatch(requestTeamData(userId))
    }).catch(error => {
        dispatch({ type: 'RECIVED_CREATE_SCRIMMAGE_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_CREATE_SCRIMMAGE' });
}

export const requestScrimmageData = (gameid) => async dispatch => {
    axios.get('game/' + gameid).then(response => {
        var scrimmageInvitationDataByMail = response.data;
        dispatch({ type: 'RECIVED_GET_SCRIMMAGE_SUCCESS', scrimmageInvitationDataByMail });
        dispatch(requestLeagueData(scrimmageInvitationDataByMail.team.id))
    }).catch(error => {
        dispatch({ type: 'RECIVED_GET_SCRIMMAGE_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_GET_SCRIMMAGE' });
}

export const requestScrimmageHistory = (teamid) => async dispatch => {
    axios.get('gamerequest?completed='+ teamid ).then(response => {
        var scrimmageHistory = response.data
        dispatch({ type: 'RECIVED_SCRIMMAGE_HISTORY_SUCCESS', scrimmageHistory});
    }).catch(error => {
        dispatch({ type: 'RECIVED_SCRIMMAGE_HISTORY_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_SCRIMMAGE_HISTORY' });
}

export const requestScrimmageRequest = (teamID) => async dispatch => {
    axios.get('game?request=' + teamID).then(response => {
        var scrimmageRequestData = response.data;
        dispatch({ type: 'RECIVED_SCRIMMAGE_REQUEST_SUCCESS', scrimmageRequestData });
    }).catch(error => {
        dispatch({ type: 'RECIVED_SCRIMMAGE_REQUEST_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_SCRIMMAGE_REQUEST' });
}

export const requestScrimmageInvitation = (teamID) => async dispatch => {
    axios.get('gamerequest?invite=' + teamID).then(response => {
        var scrimmageInvitationData = response.data;
        dispatch({ type: 'RECIVED_SCRIMMAGE_INVITATION_SUCCESS', scrimmageInvitationData });
    }).catch(error => {
        dispatch({ type: 'RECIVED_SCRIMMAGE_INVITATION_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_SCRIMMAGE_INVITATION' });
}

export const requestScrimmageConfirmation = (teamID) => async dispatch => {
    axios.get('gamerequest?scheduled=' + teamID).then(response => {
        var scrimmageConfirmationData = response.data;
        dispatch({ type: 'RECIVED_SCRIMMAGE_CONFIRMATION_SUCCESS', scrimmageConfirmationData });
    }).catch(error => {
        dispatch({ type: 'RECIVED_SCRIMMAGE_CONFIRMATION_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_SCRIMMAGE_CONFIRMATION' });
}

export const requestScrimmageDecline = (requestId, declineData) => async dispatch => {
    axios.put('gamerequest/' + requestId, declineData).then(response => {
        var scrimmageRequestDecline = response.data;
        dispatch({ type: 'RECIVED_SCRIMMAGE_REQUEST_DECLINE_SUCCESS', scrimmageRequestDecline });
    }).catch(error => {
        dispatch({ type: 'RECIVED_SCRIMMAGE_REQUEST_DECLINE_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_SCRIMMAGE_REQUEST_DECLINE' });
}

export const requestScrimmageAccept = (requestId, acceptData) => async dispatch => {
    axios.put('gamerequest/' + requestId, acceptData).then(response => {
        var scrimmageRequestAccepted = response.data;
        dispatch({ type: 'RECIVED_SCRIMMAGE_REQUEST_ACCEPT_SUCCESS', scrimmageRequestAccepted });
    }).catch(error => {
        dispatch({ type: 'RECIVED_SCRIMMAGE_REQUEST_ACCEPT_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_SCRIMMAGE_REQUEST_ACCEPT' });
}

export const requestScrimmageCancel = (data,gameid) => async dispatch => {
    axios.put('game/' + gameid, data).then(response => {
        dispatch({ type: 'RECIVED_SCRIMMAGE_REQUEST_CANCEL_SUCCESS'});
    }).catch(error => {
        dispatch({ type: 'RECIVED_SCRIMMAGE_REQUEST_CANCEL_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_SCRIMMAGE_REQUEST_CANCEL' });
}


export const requestScrimmageFeedback = (gameid, teamid, data) => async dispatch => {
    axios.post('gamerequest/'+ gameid + '/team/' + teamid + '/feedback', data).then(response => {
        var scrimmageFeedback = response.data
        dispatch({ type: 'RECIVED_SCRIMMAGE_FEEDBACK_SUCCESS', scrimmageFeedback});
        localStorage.setItem("feedbackId",scrimmageFeedback.feedback[0].id)
        dispatch(requestScrimmageFeedbackData(gameid, teamid))
    }).catch(error => {
        dispatch({ type: 'RECIVED_SCRIMMAGE_FEEDBACK_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_SCRIMMAGE_FEEDBACK' });
}

export const requestScrimmageFeedbackData = (gameid, teamid) => async dispatch => {
    axios.get('gamerequest/'+ gameid + '/team/' + teamid + '/feedback').then(response => {
        var scrimmageFeedbackData = response.data 
        dispatch({ type: 'RECIVED_SCRIMMAGE_FEEDBACK_DATA_SUCCESS', scrimmageFeedbackData});
    }).catch(error => {
        dispatch({ type: 'RECIVED_SCRIMMAGE_FEEDBACK_DATA_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_SCRIMMAGE_FEEDBACK_DATA' });
}

export const requestPaymentStatus = (userid) => async dispatch => {
    axios.get("user/"+userid).then(res=>{
        var scrimmagePaymentData = res.data 
        dispatch({ type: 'RECIVED_SCRIMMAGE_PAYMENT_DATA_SUCCESS', scrimmagePaymentData});
    }).catch(error => {
        dispatch({ type: 'RECIVED_SCRIMMAGE_PAYMENT_DATA_FAILURE' });
    });
    dispatch({ type: 'REQUESTED_SCRIMMAGE_PAYMENT_DATA' });
}

export const initialDefault = () => async dispatch => {
    dispatch({ type: 'DEFAULT_SCRIMMAGE' });
}
