import React, { Component, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { connect } from 'react-redux';
import axios from "axios";
import Spinner from '../component/Spnner';
import { toast } from "react-toastify";
import { Link, withRouter } from 'react-router-dom';
import { FaUserAlt, FaPowerOff, FaUserFriends, FaDollarSign } from 'react-icons/fa';
import { emailValidate } from "../../Util";
import { requestProfileData, requestVerifyProfileData } from '../../actions/profileActionCreator';
import { initialDefault } from '../../actions/profileActionCreator';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();


function ShowCOVID() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="container-fluid COVIDlink">
        <div className="container py-1 text-white z-index-3">
          <Collapse in={open}><a href="#!" onClick={() => setOpen(!open)} aria-controls="COVID-message" aria-expanded={open} className="position-absolute top-0 right-0 mr-3 text-white"><i className="fas fa-times fa-lg"></i></a></Collapse>
          <div className="COVIDresponse text-center text-lh-xs"><a href="#!" className="font-size-base text-center text-white font-weight-bold" onClick={() => setOpen(!open)} aria-controls="COVID-message" aria-expanded={open}>GameFinder365 COVID-19 <span className="text-nowrap">Response and Announcement of</span> <span className="text-nowrap"><em>Return to Play</em> Program &nbsp;<i className="fas fa-angle-right"></i></span></a></div>
          <Collapse in={open}>
            <div>
              <hr className="mt-1 opacity-lg" />
	      <div className="w-lg-75 mx-md-auto mb-5">
	        <p className="text-white font-size-20 text-center text-lh-sm">As we all find ourselves in the midst of a worldwide pandemic, everyone at GameFinder365 wishes you continued safety and well-being. When it comes to fighting the virus, we are all on the same team, and we will all get through this together.</p>
	        <div className="columnize-2">
	          <p className="text-white font-size-base text-lh-sm mb-3">From little leagues to professional leagues, it's clear that the way we all will participate in sports in the short term will change, but ultimately, the desire to play, compete, improve and feel a part of something bigger will resume, evolve and endure.</p>
	          <p className="text-white font-size-base text-lh-sm mb-3">In that spirit, and as we all work to keep each other safe today, GameFinder365 is preparing for a better sports future for our kids, families and communities. We are calling this our <strong><em>Return to Play</em></strong> program, and it's all about supporting youth teams of all skills and levels getting back on the courts and fields in the quickest, easiest and safest way possible.</p>
	          <p className="text-white font-size-base text-lh-sm mb-3">To help coordinate and facilitate this, coaches of all levels of team can proactively <a href="/signup" className="custom-color">register their teams</a> at GameFinder365.com and for a limited time we are offering <a href="/pricing" className="custom-color">FREE accounts</a> for all coaches.</p>
	          <p className="text-white font-size-base text-lh-sm mb-3">Once cities, states and sports leagues give the "all clear" or "game on" notice, GameFinder365 will then connect registered teams with one another for,  tryouts, scrimmages, gyms, fields and events in their local area as well as share all related safety notices and guidelines.</p>
	          <p className="text-white font-size-base text-lh-sm mb-3">To be a part of our <strong><em>Return to Play</em></strong> program, simply go to GameFinder365.com.</p>
	        </div>
	      </div>
            </div>
          </Collapse>
        </div>
      </div>
    </>
  )
}

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: '',
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      password: "",
      resetFirstNameError: "",
      resetLastNameError: "",
      resetPhoneError: "",
      resetEmailError: "",
      resetPasswordError:"",
      userName: "",
      token: "",
      COVID: false
    };
  }  

  componentDidMount = () => {
    this.setState({ pathname: window.location.pathname })
    this.setState({
      firstName: this.props.profileState.profileData && this.props.profileState.profileData.firstName,
      lastName: this.props.profileState.profileData && this.props.profileState.profileData.lastName,
      phone: this.props.profileState.profileData && this.props.profileState.profileData.phone,
      email: this.props.profileState.profileData && this.props.profileState.profileData.email,
    })
    this.setState({
      token: localStorage.getItem('user_token'),
      userName: localStorage.getItem('user_name'),
      resetEmailError:"",
      resetPasswordError:""
     })
  }

  handleLogout = () => {
    window.localStorage.clear();
    sessionStorage.clear();
    this.props.history.push("/")
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value })
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.profileState.errorStatus ===401){
      localStorage.clear();
      window.location.href="/login"
    }
    if(nextProps.profileState.status === 2){
      switch(nextProps.profileState.profilePassErr){
        case "Email is already in use":
          this.setState({
            resetEmailError:nextProps.profileState.profilePassErr,
            email: this.state.email,
            firstName: nextProps.profileState.profileData && nextProps.profileState.profileData.firstName,
            lastName: nextProps.profileState.profileData && nextProps.profileState.profileData.lastName,
            phone: nextProps.profileState.profileData && nextProps.profileState.profileData.phone,
          })
          break;
          case "Password is Incorrect":
            this.setState({
              resetPasswordError:nextProps.profileState.profilePassErr,
              resetEmailError:""
            })
            break;
            default:
              this.setState({
                resetEmailError:""
              })
      }
    }
    
    this.setState({
      resetEmailError:"",
      resetPasswordError:"",
      firstName: nextProps.profileState.profileData && nextProps.profileState.profileData.firstName,
      lastName: nextProps.profileState.profileData && nextProps.profileState.profileData.lastName,
      phone: nextProps.profileState.profileData && nextProps.profileState.profileData.phone,
      email: nextProps.profileState.profileData && nextProps.profileState.profileData.email,
      password:""
    })
  }
  
  getProfileData = () => {
    axios.defaults.headers.common['Authorization'] = 'bearer ' + this.state.token
    let userId = localStorage.getItem("user_id")
    this.props.requestProfileData(userId)
  }

  validatePassword = (e) => {
    axios.defaults.headers.common['Authorization'] = 'bearer ' + this.state.token
    if (this.state.email) {
      const isValidEmail = emailValidate(this.state.email);
      if (isValidEmail) {
        this.setState({ resetEmailError: "" });
        const data = {
          email: this.state.email,
          password: this.state.password
        }
        if(data.password){
          this.setState({
            resetPasswordError:""
          })
          const updateData = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phone: this.state.phone
          }
          let userId = localStorage.getItem("user_id")
          this.props.requestVerifyProfileData(data, updateData, userId)
        }
      
        else{
          this.setState({
            resetPasswordError:"This field is required"
          })
        }
      }
      else{
        this.setState({
          resetEmailError: "Not a valid format"
        })
      }
  }
  else {
    this.setState({
      resetEmailError: "This field is required"
    })
  }
  }

  handleProfileUpdateModal=()=>{
    this.getProfileData();
  }
  
  render() {
    return (
      <div>
        {/*
        window.location.pathname === '/'?
        <ShowCOVID />  
        :null
        */}
        <header id="header" className="u-header u-header--modern u-header--bordered">
          <div className="u-header__section">
            <div id="logoAndNav" className="container-fluid">
              <nav className="js-mega-menu navbar navbar-expand-lg u-header__navbar hs-menu-initialized hs-menu-horizontal">
                <a className="navbar-brand u-header__navbar-brand" href="/" aria-label="Space">
                  <img className="u-header__navbar-brand-default" src="/img/login-and-signup/logo.png" alt="Logo" />
                  <img className="u-header__navbar-brand-mobile" src="/img/login-and-signup/logo.png" alt="Logo" />
                </a>

                <button type="button" className="navbar-toggler btn u-hamburger u-header__hamburger collapsed" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navBar" data-toggle="collapse" data-target="#navBar">
                  <span id="hamburgerTrigger" className="u-hamburger__box">
                    <span className="u-hamburger__inner"></span>
                  </span>
                </button>

                <div id="navBar" className="navbar-collapse u-header__navbar-collapse py-0 collapse">
                  <ul className="navbar-nav u-header__navbar-nav">
                {/* <li className="nav-item u-header__nav-item-btn">
                <a className="u-header__nav-link navtext" href="!#" aria-haspopup="true" aria-expanded="false" aria-labelledby="docsSubMenu">
                 Our Team
                </a>
                </li> */}
                <li className="nav-item u-header__nav-item-btn">
                  <Link className="u-header__nav-link navtext" to="/aboutus" aria-haspopup="true" aria-expanded="false" aria-labelledby="docsSubMenu">
                  About
                </Link>
                </li>
                <li className="nav-item u-header__nav-item-btn">
                <Link className="u-header__nav-link navtext" to="/why" aria-haspopup="true" aria-expanded="false" aria-labelledby="docsSubMenu">
                  Why GameFinder365?
                </Link>
                </li>
                {
                localStorage.getItem("user_id")!== null ? 

                    <li className="nav-item u-header__nav-item-btn mr-5">
                    <Link className="u-header__nav-link navtext" to={this.props.teamLength === 0 ?"/addteam":"/yourteam"} aria-haspopup="true" aria-expanded="false" aria-labelledby="docsSubMenu">
                      My Teams
                    </Link>
                    </li>
                : 
                
                    <li className="nav-item u-header__nav-item-btn mr-5">
                    <Link className="u-header__nav-link navtext" to="/pricing" aria-haspopup="true" aria-expanded="false" aria-labelledby="docsSubMenu">
                      Pricing
                    </Link>
                    </li>
                }
                
                {/* <li className="nav-item u-header__nav-item-btn">
                <a className="u-header__nav-link navtext" href="!#" aria-haspopup="true" aria-expanded="false" aria-labelledby="docsSubMenu">
                  How it Works
                </a>
                </li> */}
                
                    {
                    localStorage.getItem("user_id")!== null ? 
                    <ul className="navbar-nav u-header__navbar-nav">
                    <li className="nav-item u-header__nav-item-btn dropdown">
                      <a href="#!" className="btn btn-sm btn-outline-primary deleteleague text-white dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                      <FaUserAlt /> &nbsp;
                        Hello <span className="text-capitalize">{this.state.userName}</span>
                      </a>
                      <div className="dropdown-menu py-0" aria-labelledby="dropdownMenuButton">
                        {/* <Link className="dropdown-item mb-0" to={this.props.teamLength === 0 ?"/addteam":"/yourteam"}><FaUserFriends />  &nbsp; My Teams</Link>
                        <hr className="m-0" /> */}
                        <Link className="dropdown-item mb-0" to="/pricing"><FaDollarSign />  &nbsp; Billing</Link>
                        <hr className="m-0" />
                        <a className="dropdown-item" href="#!" data-toggle="modal" data-target="#profileModal" onClick={this.handleProfileUpdateModal}><FaUserAlt />  &nbsp; Profile</a>
                        <hr className="m-0" />
                        <a className="dropdown-item mb-0" href="#!" onClick={this.handleLogout}><FaPowerOff />  &nbsp; Logout</a>
                      </div>
                    </li>
                  </ul>
                    :
                      this.state.pathname !== "/login" && this.state.pathname !== "/signup" ?
                        (<li className="nav-item u-header__nav-item-btn">
                          <a className="btn btn-outline-primary mx-1 py-2" href='/signup' role="button" onClick={this.handleLogout}>
                            Sign Up
                          </a>
                          <a className="btn btn-primary mx-1 py-2" href='/login' role="button" onClick={this.handleLogout}>
                            Log In
                          </a>
                        </li>)
                        :
                        (<li className="nav-item u-header__nav-item-btn">
                          <a className="btn btn-primary signin-signup-btn mx-1" href={this.state.pathname === "/login" ? "/signup" : '/login'} role="button">
                            {this.state.pathname === "/login" ? 'Sign-Up' : 'Log In'}
                          </a>
                        </li>)
                    }

                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </header>
        <div className="modal fade" id="profileModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
            <Spinner display={this.props.isLoading}>
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" className="close">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h2 className="text-center font-weight-bold">Profile Update</h2>
                <span className="d-flex text-center subtext">Update your profile info below, password is required to make changes.</span>
                <div className="js-form-message mb-3 mt-5">
                  <div className="js-focus-state input-group input-group form">
                    <div className="input-group-prepend form__prepend">
                      <span className={this.state.resetFirstNameError ? "input-group-text form__text error" : "input-group-text form__text"}>
                        <img src="/img/login-and-signup/info.png" className="phone" alt="" />
                      </span>
                    </div>
                    <input type="text" className={this.state.resetFirstNameError ? "form-control form__input error" : "form-control form__input"} name="firstName" value={this.state.firstName || ''} placeholder="First Name" aria-label="Enter your first name" onChange={this.handleChange} />
                  </div>
                  {
                    this.state.resetFirstNameError ? <div style={{ color: "red" }}>{this.state.resetFirstNameError}</div> : null
                  }
                </div>

                <div className="js-form-message mb-3 mt-5">
                  <div className="js-focus-state input-group input-group form">
                    <div className="input-group-prepend form__prepend">
                      <span className={this.state.resetLastNameError ? "input-group-text form__text error" : "input-group-text form__text"}>
                        <img src="/img/login-and-signup/info.png" className="phone" alt="" />
                      </span>
                    </div>
                    <input type="text" className={this.state.resetLastNameError ? "form-control form__input error" : "form-control form__input"} name="lastName" value={this.state.lastName || ''} placeholder="Last Name" aria-label="Enter your last name" onChange={this.handleChange} />
                  </div>
                  {
                    this.state.resetLastNameError ? <div style={{ color: "red" }}>{this.state.resetLastNameError}</div> : null
                  }
                </div>

                <div className="js-form-message mb-3 mt-5">
                  <div className="js-focus-state input-group input-group form">
                    <div className="input-group-prepend form__prepend">
                      <span className={this.state.resetPhoneError ? "input-group-text form__text error" : "input-group-text form__text"}>
                        <img src="/img/login-and-signup/phone.png" className="phone" alt="" />
                      </span>
                    </div>
                    <input type="number" className={this.state.resetPhoneError ? "form-control form__input error" : "form-control form__input"} name="phone" min="0" value={this.state.phone || ''} placeholder="Phone" aria-label="Enter your phone" onChange={this.handleChange} />
                  </div>
                  {
                    this.state.resetPhoneError ? <div style={{ color: "red" }}>{this.state.resetPhoneError}</div> : null
                  }
                </div>

                <div className="js-form-message mb-3 mt-5">
                  <div className="js-focus-state input-group input-group form">
                    <div className="input-group-prepend form__prepend">
                      <span className={this.state.resetEmailError ? "input-group-text form__text error" : "input-group-text form__text"}>
                        <img src="/img/login-and-signup/email.png" className="phone" alt="" />
                      </span>
                    </div>
                    <input type="email" className={this.state.resetEmailError ? "form-control form__input error" : "form-control form__input"} name="email" value={this.state.email || ''} placeholder="Email" aria-label="Enter your email" onChange={this.handleChange} />
                  </div>
                  {
                    this.state.resetEmailError ? <div style={{ color: "red" }}>{this.state.resetEmailError}</div> : null
                  }
                </div>

                <div className="js-form-message mb-3 mt-5">
                  <div className="js-focus-state input-group input-group form">
                    <div className="input-group-prepend form__prepend">
                      <span className={this.state.resetPasswordError ? "input-group-text form__text error" : "input-group-text form__text"}>
                        <img src="/img/login-and-signup/pass.png" className="phone" alt="" />
                      </span>
                    </div>
                    <input type="password" className={this.state.resetPasswordError ? "form-control form__input error" : "form-control form__input"} name="password" value={this.state.password || ''} placeholder="Password" aria-label="Password" onChange={this.handleChange} />
                  </div>
                  {
                    this.state.resetPasswordError ? <div style={{ color: "red" }}>{this.state.resetPasswordError}</div> : null
                  }
                </div>

                <button type="submit" className="btn btn-block btn-primary mt-5 font-weight-bold" aria-label="Close" onClick={this.validatePassword}>Save</button>
              </div>
              </Spinner>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.TokenState,
    profileState: state.ProfileState,
    isLoading:state.ProfileState.isLoading,
    teamLength:state.TeamState.teamData && state.TeamState.teamData.length,
    initial:state
  }
};

const mapDispatchToProps = {
  requestProfileData,
  requestVerifyProfileData,
  initialDefault
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav))