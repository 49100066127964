
const defaultLoginState = {
    token: null,
    isLoading: false,
    status: 0
};

export default function (state = defaultLoginState, action) {

    switch (action.type) {

        case 'DEFAULT_SCRIMMAGE':
            return { ...state, isLoading: false, status: 0, forgotPassStatus:null, };

        case 'REQUESTED_USER_SIGN_UP':
            return { ...state, isLoading: true, status: 1 };
        case 'RECIVED_USER_SIGN_UP_FAILURE':
            return { ...state, isLoading: false, signupError:action.signupError, status: 2 };
        case 'RECIVED_USER_SIGN_UP_SUCCESS':
            return { ...state, signUpToken: action.signUpToken, isLoading: false, status: 3 };
            
            case 'REQUESTED_USER_BASIC_DETAILS':
            return { ...state, isLoading: true, status: 1 };
        case 'RECIVED_USER_BASIC_DETAILS_FAILURE':
            return { ...state, isLoading: false, status: 2 };
        case 'RECIVED_USER_BASIC_DETAILS_SUCCESS':
            return { ...state, basicDetailsData: action.responsedata, isLoading: false, status: 3 };

        case 'REQUESTED_FORGET_PASSWORD':
            return { ...state, isLoading: true, status:1 };
        case 'RECIVED_FORGET_PASSWORD_FAILURE':
            return { ...state, isLoading: false, status:2 };
        case 'RECIVED_FORGET_PASSWORD_SUCCESS':
            return { ...state, isLoading: false, status: 3 };

        case 'REQUESTED_RESET_PASSWORD':
            return { ...state, isLoading: true };
        case 'RECIVED_FORGET_RESET_FAILURE':
            return { ...state, isLoading: false, error: action.error };
        case 'RECIVED_FORGET_RESET_SUCCESS':
            return { ...state, isLoading: false };

            case 'RECIVED_RESET_PASSWORD':
                return { ...state, isLoading: true, status:1 };
            case 'RECIVED_RESET_PASSWORD_FAILURE':
                return { ...state, isLoading: false, status:2};
            case 'RECIVED_RESET_PASSWORD_SUCCESS':
                return { ...state, isLoading: false, forgotPassStatus: action.forgotPassStatus, status:3 };
        default: 
    }

    return state
}